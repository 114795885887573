import React from "react";
import { connect } from "react-redux";
import { PanelList } from '../../constant/PostAdsPanelList'
import { PostAboutTheRoomDetails } from '../../api/apiLoader'

class AboutTheRoom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            AboutTheRoomInfo: {
                kind: 0,
                development: "",
                monthlyrent: "",
                roomarea: "",
                chargesnotincluded: false,
                privateBathroom: false,
                availabilityDate: "",
                minimumduration: ""
            },
            ValidateErrorMessage: {
                kind: "",
                development: "",
                monthlyrent: "",
                availabilityDate: ""
            },
        }
    }
    handleAboutRoomChange = (event) => {
        const { AboutTheRoomInfo, ValidateErrorMessage } = this.state;
        const { kind, development, monthlyrent, availabilityDate } = AboutTheRoomInfo;

        if (event.target.name === "kind") {
            if (Number(event.target.value) === 0) {
                ValidateErrorMessage.kind = "Please select kind of room";
            }
            else {
                ValidateErrorMessage.kind = "";
            }
        }
        if (event.target.name === "development") {
            if (Number(event.target.value) === 0) {
                ValidateErrorMessage.development = "Please select development";
            }
            else {
                ValidateErrorMessage.development = "";
            }
        }
        if (event.target.name === "monthlyrent") {
            if (Number(event.target.value) <= 0) {
                ValidateErrorMessage.monthlyrent = "Please enter the monthly rent";
            }
            else {
                ValidateErrorMessage.monthlyrent = "";
            }
        }
        if (event.target.name === "monthlyrent") {
            if (Number(event.target.value) <= 0) {
                ValidateErrorMessage.monthlyrent = "Please enter the monthly rent";
            }
            else {
                ValidateErrorMessage.monthlyrent = "";
            }
        }
        if (event.target.name === "availabilityDate") {
            if (event.target.value.trim() === "") {
                ValidateErrorMessage.availabilityDate = "Please enter the availability date";
            }
            else {
                ValidateErrorMessage.availabilityDate = "";
            }
        }
        AboutTheRoomInfo[event.target.name] = event.target.value;
        (async () => {
            this.setState({
                AboutTheRoomInfo: { ...AboutTheRoomInfo },
                ValidateErrorMessage: { ...ValidateErrorMessage }
            });
        })();
    }
    handleChargesIncludedAndPrivateRoomChange = (event) => {
        const { checked } = event.target;
        const { AboutTheRoomInfo } = this.state;
        AboutTheRoomInfo[event.target.name] = checked;
        this.setState({ AboutTheRoomInfo: { ...AboutTheRoomInfo } })
    };
    onClickContinue = (panel) => {
        const { AboutTheRoomInfo } = this.state;
        const isAboutRoomValid = this.isValidAbouttheRoomData(AboutTheRoomInfo);
        const { kind, development, monthlyrent, roomarea, chargesnotincluded,
            privateBathroom, availabilityDate, minimumduration } = AboutTheRoomInfo;
        if (isAboutRoomValid) {
            const payload = {
                postMyAdId: this.props.PostAdsId,
                roomKindId: Number(kind),
                roomDevelopmentId: Number(development),
                monthlyRent: Number(monthlyrent),
                roomArea: Number(roomarea),
                isChargesNotIncluded: chargesnotincluded,
                hasPrivateBathroom: privateBathroom,
                availabilityDate: availabilityDate === "" ? null : availabilityDate,
                minimumDuration: Number(minimumduration)
            }
            PostAboutTheRoomDetails(payload).then(response => {
                const { isSuccess } = response.data;
                if (isSuccess) {
                    this.setState({ isLoading: false }, () => {
                        this.props.callbackNavigatePanel(panel)
                    })
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }
    isValidAbouttheRoomData = (RoomData) => {
        let isValid = true;
        const { kind, development, monthlyrent, availabilityDate } = RoomData;
        const { ValidateErrorMessage } = this.state;
        if (Number(kind) === 0) {
            ValidateErrorMessage.kind = "Please select kind of room";
            isValid = false;
        }
        else {
            ValidateErrorMessage.kind = "";
        }
        if (Number(development) === 0) {
            ValidateErrorMessage.development = "Please select development";
            isValid = false;
        }
        else {
            ValidateErrorMessage.development = "";
        }
        if (Number(monthlyrent) <= 0) {
            ValidateErrorMessage.monthlyrent = "Please enter the monthly rent";
            isValid = false;
        }
        else {
            ValidateErrorMessage.monthlyrent = "";
        }
        if (availabilityDate.trim() === "") {
            ValidateErrorMessage.availabilityDate = "Please enter the availability date";
            isValid = false;
        }
        else {
            ValidateErrorMessage.availabilityDate = "";
        }
        this.setState({ ValidateErrorMessage: { ...ValidateErrorMessage } })
        return isValid;
    }
    render() {
        const { isLoading, AboutTheRoomInfo, ValidateErrorMessage } = this.state;
        return (
            <>
                <div className="row">
                    <div className="col">
                        <h2 className="section-title text-start mt-3 m-2 pb-2">About the Room</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group text-start">
                                <label className="m-2">Kind<span className="text-danger"> *</span></label>
                                <select className="form-control form-select pt-2" name="kind" value={AboutTheRoomInfo.kind}
                                    onChange={this.handleAboutRoomChange}>
                                    <option value="0">Please choose room type</option>
                                    {this.props.roomkindcontents.map((roomkindcontent) => (
                                        <option key={roomkindcontent.value} value={roomkindcontent.key}>{roomkindcontent.value}</option>
                                    ))}
                                </select>
                                {
                                    ValidateErrorMessage.kind && <p className="text-danger">{ValidateErrorMessage.kind}</p>
                                }
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group text-start">
                                <label className="m-2">Development <span className="text-danger">*</span></label>
                                <select className="form-control form-select pt-2" name="development" value={AboutTheRoomInfo.development} onChange={this.handleAboutRoomChange}>
                                    <option value="0">Please choose development type</option>
                                    {this.props.roomDevelopmentcontents.map((roomDevelopmentcontent) => (
                                        <option key={roomDevelopmentcontent.value} value={roomDevelopmentcontent.key}>{roomDevelopmentcontent.value}</option>
                                    ))}
                                </select>
                                
                            </div>
                            <div>
                            {
                                    ValidateErrorMessage.development && <p className="text-danger">{ValidateErrorMessage.development}</p>
                                }
                            </div>
                        </div>
                    </div>
                     <div className="row">
                        <div className="col-md-6 input-from input">
                            <div className="form-group text-start m-2 pt-2">
                            <label for="monthly">Monthly rent (€)<span class="text-danger">*</span></label>
                            <div class="input-group pt-3">
                                <input type="number" className="form-control pt-2" name="monthlyrent" value={AboutTheRoomInfo.monthlyrent}
                                    onChange={this.handleAboutRoomChange} />
                                    <span className="input-group-text">€</span>
                               
                            </div>
                            <div>
                            {
                                    ValidateErrorMessage.monthlyrent && <p className="text-danger">{ValidateErrorMessage.monthlyrent}</p>
                                }
                            </div>
                        </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group text-start">
                                <label className="m-2 pt-3">Room area (m²)</label>
                                <input type="number" className="form-control pt-2" name="roomarea" value={AboutTheRoomInfo.roomarea} onChange={this.handleAboutRoomChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
 
                            <div className="form-check text-start mt-4">
                                <input type="checkbox" className="form-check-input" name="chargesnotincluded" checked={AboutTheRoomInfo.chargesnotincluded} onChange={this.handleChargesIncludedAndPrivateRoomChange} />
                                <label className="form-check-label">Charges not included</label>
                            </div>
                            </div>
                            <div className="col-md-6">
                            <div className="form-check text-start mt-4">
                                <input type="checkbox" className="form-check-input" name="privateBathroom" checked={AboutTheRoomInfo.privateBathroom} onChange={this.handleChargesIncludedAndPrivateRoomChange} />
                                <label className="form-check-label">Private bathroom</label>
                            </div>
                           
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-md-6">
 
                              <div className="form-group text-start mt-4">
                                <label className="m-2">Availability date <span className="text-danger">*</span></label>
                                <input type="date" className="form-control pt-2" name="availabilityDate" value={AboutTheRoomInfo.availabilityDate} onChange={this.handleAboutRoomChange} />
                                {
                                    ValidateErrorMessage.availabilityDate && <p className="text-danger">{ValidateErrorMessage.availabilityDate}</p>
                                }
                            </div>
                            </div>
                            <div className="col-md-6">
                            <div className="form-group text-start mt-4">
                                <label className="m-2">Minimum duration (months)</label>
                                <input type="number" className="form-control pt-2" name="minimumduration" value={AboutTheRoomInfo.minimumduration} onChange={this.handleAboutRoomChange} />
                            </div>
                       
                        </div>
                        </div>
                   
 
                    <div className="row justify-content-center p-3">
                        <div className="col-auto">
                            {
                                isLoading && <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            }
                            <input type="button" value={"Continue"} className="btn btn-continue"
                                onClick={() => this.onClickContinue(PanelList.CURRENT_ROOMMATES)}></input>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(AboutTheRoom)
