import React from "react";
import { withRouter } from "../../hooks/useRouteHooks";
import "./Hotel.css";
import { setKey } from "react-geocode";
import { GetMasterData, GetPropertyByFilterList, GetPropertyListByCityname, GetPropertyListByStatename, GetPropertyListings } from "../../api/apiLoader";
import { property } from "lodash";
import PropertyCards from "./propertycards";
import { imgAssests } from "../../assets/imgAssests/assests";
 
 
class HotelList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPriceRange: 100,
      propertyListscontents: [],
      count: 0,
      chunks: "",
      loading:false,
 
      //For Master API
      HouseTypeList: [],
      RoomDevelopmentList: [],
      RoomKindList: [],
      SharedHousingRuleList: [],
      AdsPostUserRoleList: [],
      AmenitiesList: [],
    }
  }
 
  getPropertyDataByFilter = () => {
    this.setState({ loading: true });
    console.log(this.props.router)
    const {params} = this.props.router;
    const {option,countryname,subname} = params || {};
    console.log(option,countryname,subname); 
  
    const {selectedPriceRange,HouseTypeList,RoomDevelopmentList,RoomKindList,
      SharedHousingRuleList,AmenitiesList } = this.state;
  
      const selectedHouseList = HouseTypeList.filter((e) => e.checked === true).map((e) => e.key)
      const selectedRoomDevelopmentList = RoomDevelopmentList.filter((e) => e.checked === true).map((e) => e.key)
      const selectedRoomKindList = RoomKindList.filter((e) => e.checked === true).map((e) => e.key)
      const selectedSharedHousingRuleList = SharedHousingRuleList.filter((e) => e.checked === true).map((e) => e.key)
      const selectedAmenitiesList = AmenitiesList.filter((e) => e.checked === true).map((e) => e.key)
      
      // Payload Example for Filter API
      const payload = {
        priceRange: {
          minimumAmount: selectedPriceRange,
          maxAmount: 2000,
        },
        housingTypeList: selectedHouseList,
        amenitiesList: selectedAmenitiesList,
        roomKindList: selectedRoomKindList,
        roomDevelopmentList: selectedRoomDevelopmentList,
        rulesList: selectedSharedHousingRuleList,
      };
      console.log(payload);

      // Checks if Filters Applied

      if (this.isAnyFilterSelected()) {

        GetPropertyByFilterList(payload)
            .then((response) => {
                const result = response.data;
                this.setState({ propertyListscontents: result.availablePropertyList });
                this.setState({ loading: false });
            })
            .catch((error) => {
                console.log("There was an error while fetching the filtered property listings", error);
                this.setState({ loading: false });
            });
      }

      //Property View of Countryname with Statename or Cityname

      if(option === "State") {
        GetPropertyListByStatename(countryname,subname)
        .then((response) => {
            const result = response.data;
            this.setState({ propertyListscontents: result.availablePropertyList }); 
            this.setState({ loading: false }); 
          })
          .catch((error) => {
            console.log("There was an error occured during the process", error);
            this.setState({ loading: false });
          })
      } 
      else if(option === "City")
      {
        GetPropertyListByCityname(countryname,subname)
        .then((response) => {
            const result = response.data;
            this.setState({ propertyListscontents: result.availablePropertyList });  
            this.setState({ loading: false });    
          })
          .catch((error) => {
            console.log("There was an error occured during the process" , error);
            this.setState({ loading: false });
          }) 
      }
      // Default View of Property Listings
      else {
        GetPropertyListings()
        .then((response) => {
          const result = response.data;
          this.setState({ propertyListscontents: result.availablePropertyList });
          this.setState({ loading: false }); 
        })
        .catch((error) => {
          console.log("There was an error occured during the process", error);
          this.setState({ loading: false });
        })

      }
  }
    componentDidMount() {
      // this.setBackgroundImage();
      this.getPropertyDataByFilter();
  
      //API for Filter Section filler  
      GetMasterData()
        .then((response) => {
          const result = response.data;
  
          const HouseTypeList = result.housingTypeList.map((house) => {
            return { key: house.key, value: house.value, checked: false }
          })
  
          const RoomDevelopmentList = result.roomDevelopmentList.map((room) => {
            return { key: room.key, value: room.value, checked: false }
          })
  
          const RoomKindList = result.roomKindList.map((room) => {
            return { key: room.key, value: room.value, checked: false }
          })
          const SharedHousingRuleList = result.sharedHousingRulesList.map((house) => {
            return { key: house.key, value: house.value, checked: false }
          })
          const AdsPostUserRoleList = result.postMyAdRoleList.map((role) => {
            return { key: role.key, value: role.value, checked: false }
          })
          const AmenitiesList = result.amenitiesList.map((rule) => {
            return { key: rule.key, value: rule.value, checked: false }
          })
          this.setState({
            HouseTypeList,
            RoomDevelopmentList,
            RoomKindList,
            SharedHousingRuleList,
            AdsPostUserRoleList,
            AmenitiesList
          })
        })
        .catch((error) => {
          console.error("There was an error while fetching the master data", error);
        });
        this.getPropertyDataByFilter();
    }

    setPriceRange = (price) => {
      // console.log(price)
      this.setState({ selectedPriceRange: price })
    }

    
    isAnyFilterSelected = () => {
      const {
        HouseTypeList,
        RoomDevelopmentList,
        RoomKindList,
        SharedHousingRuleList,
        AmenitiesList,
      } = this.state;
  
      return (
        HouseTypeList.some((item) => item.checked) ||
        RoomDevelopmentList.some((item) => item.checked) ||
        RoomKindList.some((item) => item.checked) ||
        SharedHousingRuleList.some((item) => item.checked) ||
        AmenitiesList.some((item) => item.checked)
      );
    };
  

    setBackgroundImage = () => {
      // Set background image to this stage
    };
  
    handleRulesAcceptedChange = (event) => {    
  
      const {value, checked,name} = event.target;
      const tempList = [...this.state[name]]
      // console.log("templist:" , tempList);
      let item = tempList.find((e) => e.key === Number(value));
      item.checked = checked;
      this.setState({[name]:[...tempList]},()=>{
      // this.getPropertyDataByFilter();
      });
    };
  
    render() {
      const { loading ,selectedPriceRange } = this.state;
      return (
        <div className="background-property mt-5">
          <div className="row search-row d-flex justify-content-center">
            <h4 className="mt-5">Find Best Place, Restaurant, and many more in just one Click</h4>
            {/* Search Section needs to be implemented here */}
            <div className="filtersearchSection w-75 ">
              <div className="input-group">
                <input type="text" class="form-control" placeholder="What? Ex: Place, Restaurant" aria-label="Username" />
                <input type="text" class="form-control" placeholder="Location Ex: US, New York ..." aria-label="Server" />
                <button>Search&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" /></svg></button>
              </div>
            </div>
          </div>
  
          {/* Breadcrumb Link for Routing */}
          <div className="row mt-5 ms-2">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-propertydetailview">
                <li class="breadcrumb-item"><a href="#">BLLLD</a></li>
                <li class="breadcrumb-item"><a href="#">Property Listings at Selected Location</a></li>
              </ol>
            </nav>
          </div>
  
  
          {/* Sidebar Filter Section */}
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-12 sidebar mt-4">
              <div className="filter-section p-4">
                <h3>Filters</h3>
  
                {/* Price Range */}
                <div className="filter-group mt-3">
                  <h6>
                    Price Range <br />
                    <span className="normal-font">€{selectedPriceRange} to €2000</span>
                  </h6>
                  <label htmlFor="customRange1" className="form-label"></label>
                  <input type="range" className="form-range" id="customRange1" defaultValue="100" min="100" max="2000" step="100" onChange={(event) => this.setPriceRange(event.target.value)} />
                </div>
  
                {/* Property Type */}
                <h6>Property Type</h6>
                <div className="filter-group mt-2 grid-gap-0 row-gap-2">
                  {this.state.HouseTypeList?.map((house) => (
                    <div key={`house${house.key}`} className="col-12">
                      <input
                        type="checkbox"
                        id={house.value}
                        name="HouseTypeList"
                        value={house.key}
                        checked={house.checked}
                        onChange={(event) => this.handleRulesAcceptedChange(event)}
                      />
                      <label htmlFor={house.value}>&nbsp;&nbsp;{house.value}</label>
                    </div>
                  ))}
                </div>
  
                {/* Amenities */}
                <h6 className="mt-2">Amenities</h6>
                  <div className="filter-group mt-2 d-flex flex-wrap gap-2">
                    {this.state.AmenitiesList?.map((amenities) => (
                      <div key={amenities.value}>
                        <input type="checkbox"
                        id={amenities.value}
                        name="AmenitiesList"
                        value={amenities.key}
                        checked={amenities.checked}
                        onChange={(event) => this.handleRulesAcceptedChange(event)}
                        />
                        <label htmlFor={amenities.value}>&nbsp;&nbsp;{amenities.value}</label>
                      </div>
                    ))}
                  </div>
  
                {/* Room Developed Type */}
                <h6 className="mt-2">Room Developed Type</h6>
                <div className="filter-group mt-2 d-flex flex-wrap gap-2">
                  {this.state.RoomDevelopmentList?.map((room) => (
                    <div key={`roomdevelop${room.key}`} className="col-6 col-md-12">
                      <input
                        type="checkbox"
                        id={room.value}
                        name="RoomDevelopmentList"
                        value={room.key}
                        checked={room.checked}
                        onChange={(event) => this.handleRulesAcceptedChange(event)}
                      />
                      <label htmlFor={room.value}>&nbsp;&nbsp;{room.value}</label>
                    </div>
                  ))}
                </div>
  
                {/* Rules */}
                <h6 className="mt-2">Rules</h6>
                  <div className="filter-group mt-2 d-flex flex-wrap gap-2">
                    {this.state.SharedHousingRuleList?.map((rule) => (
                      <div key={rule.value}>
                        <input type="checkbox"
                        id={rule.value}
                        name="SharedHousingRuleList"
                        value={rule.key}
                        checked={rule.checked}
                        onChange={(event) => this.handleRulesAcceptedChange(event)}
                        />
                        <label htmlFor={rule.value}>&nbsp;&nbsp;{rule.value}</label>
                      </div>
                    ))}
                  </div>
  
                {/* Kind */}
                <h6 className="mt-2">Kind</h6>
                <div className="filter-group mt-2 d-flex flex-wrap gap-2">
                  {this.state.RoomKindList?.map((kind) => (
                    <div key={kind.value} className="col-6 col-md-12">
                      <input
                        type="checkbox"
                        id={kind.value}
                        name="RoomKindList"
                        value={kind.key}
                        checked={kind.checked}
                        onChange={(event) => this.handleRulesAcceptedChange(event)}
                      />
                      <label htmlFor={kind.value}>&nbsp;&nbsp;{kind.value}</label>
                    </div>
                  ))}
                </div>
                <div className="filter-submit d-flex justify-content-center">
                  {this.isAnyFilterSelected() && <button className="btn btn-sm" onClick={this.getPropertyDataByFilter}>Submit</button>}  
                </div>
              </div>
            </div>

            {/* Property Listings Component */}
            <div className="col-12 col-md-9 mt-4">
              <h5 className="property-mainHeader">
                <strong>100 PG/Apartment</strong> Waiting to be yours in Test City
              </h5>
              {/* Loading Spinner */}
              {loading ? (
                <div className="property-notfoundsection">
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-danger" role="status">
                      <span className="visually-hidden">Hold on.. We Try to fetch the data</span>
                    </div>
                  </div>
                </div>
                ) : (
                  <div>
                    {/* Display property list if available */}
                    {this.state.propertyListscontents.length !== 0 ? (
                      this.state.propertyListscontents.map((property) => (
                        <PropertyCards key={property.id} property={property} />
                      ))
                    ) : (
                      /* No properties found */
                      <div className="property-notfoundsection">
                        <div className="d-flex justify-content-center">
                          <img src={imgAssests.datanotfound} alt="Property Not Found" />
                        </div>
                        <h5>No Property Listings were Found that match your Criteria</h5>
                      </div>
                    )}
                  </div>
                )}
             </div>
          </div>
        </div>
      );
    }
  }

export default withRouter(HotelList);

