import React from "react";
import { withRouter } from "../../hooks/useRouteHooks";
import "./Hotel.css";
import { imgAssests } from "../../assets/imgAssests/assests";
import { map } from "lodash";
import { Navigate, redirect } from "react-router-dom";
import { directionsURL, GetAdsInfo, GetMasterData, mapLoaderURL } from "../../api/apiLoader";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from "react-bootstrap";
 
 
class HotelInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state={
      //For property viewer
      propertyinfocontents:[],
 
      //For Master API
      housingTypecontents:[],
      roomDevelopmentcontents:[],
      roomkindcontents:[],
      sharedhousingRulescontents :[],  
      rolelistcontents :[],  
      amenitieslistcontents : [],
      lat: null,
      lng: null
    };
    this.mapRef = React.createRef();
  }
 
 
  componentDidMount() {
    const state = this.props.router.location.state || {};
    const params = this.props.router.params || {};
    const postMyAdId = params.HotelId || {};
    this.setBackgroundImage();
 
 
    // Master data API Call
    GetMasterData()
      .then((response) => {
        const result = response.data;
        this.setState({
          housingTypecontents: result.housingTypeList,
          roomDevelopmentcontents: result.roomDevelopmentList,
          roomkindcontents: result.roomKindList,
          sharedhousingRulescontents: result.sharedHousingRulesList,
          rolelistcontents: result.postMyAdRoleList,
          amenitieslistcontents: result.amenitiesList
        })
      })
      .catch((error) => {
        console.error("There was an error while fetching the master data", error);
      });
 
     
    // Property info API
    // axios.get(`${API_URL}/api/PostMyAd/GetPostMyAdById?postMyAdId=${postMyAdId}`)
    // console.log(postMyAdId)
    GetAdsInfo(postMyAdId)
      .then((response) => {
        const result = response.data;
        this.setState({
          propertyinfocontents: result ,
          lat: result.postMyAdAddress.latitudes,
          lng: result.postMyAdAddress.longitudes,
        });
      })
      .catch((error) => {
        console.log("There was an error while fetching the property info", error);
      });
 
    window.addEventListener("scroll", this.handleScroll);
   
  }
 
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
 
 
  // Dynamically load the Google Maps API
 
  loadGoogleMaps(lat, lng) {
    if (!window.google) {
      const script = document.createElement('script');
      script.src = mapLoaderURL;
      script.async = true;
      script.defer = true;
      script.onload = () => this.initializeMap(lat, lng);
      script.onerror = () => console.error("Failed to load Google Maps script.");
      document.head.appendChild(script);
    } else {
      this.initializeMap(lat, lng);
    }
  }
 
  initializeMap(lat, lng) {
    // Add a check to ensure mapRef.current is not undefined
    if (this.mapRef.current) {
      const myLatLng = { lat, lng };
      const map = new window.google.maps.Map(this.mapRef.current, {
        center: myLatLng,
        zoom: 12,
      });
 
      // Add a marker position to ensure the correct lat and lng based on the stored data
      new window.google.maps.Marker({
        position: myLatLng,
        map,
        title: 'Property Location',
      });
    } else {
      console.error("Map reference is not defined");
    }
  }
 
  //Directions API Updated Version Code
 
  // initializeMap(lat, lng) {
  //   if (this.mapRef.current) {
  //     const map = new window.google.maps.Map(this.mapRef.current, {
  //       center: { lat, lng },
  //       zoom: 12,
  //     });
 
  //     const directionsService = new window.google.maps.DirectionsService();
  //     const directionsRenderer = new window.google.maps.DirectionsRenderer();
  //     directionsRenderer.setMap(map);
 
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition((position) => {
  //         const userLocation = {
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude,
  //         };
 
  //         const request = {
  //           origin: userLocation,
  //           destination: { lat, lng },
  //           travelMode: window.google.maps.TravelMode.WALKING,
  //         };
 
  //         directionsService.route(request, (result, status) => {
  //           if (status === window.google.maps.DirectionsStatus.OK) {
  //             directionsRenderer.setDirections(result);
  //           } else {
  //             console.error('Directions request failed due to ' + status);
  //           }
  //         });
  //       }, (error) => {
  //         console.error("Error while fetching the current location", error);
  //       });
  //     } else {
  //       new window.google.maps.Marker({
  //         position: { lat, lng },
  //         map,
  //         title: 'Selected Property Location',
  //       });
  //     }
  //   } else {
  //     console.error("Map reference is not defined");
  //   }
  // }
   
  handleScroll = () => {
    const mapsection = document.querySelector('.map-section');
    const moreDetailssection = document.querySelector('.moreDetailssection');
 
    const scrollY = window.scrollY;
    const mapSectionOffsetTop = moreDetailssection.offsetTop;
 
    if (scrollY >= mapSectionOffsetTop) {
      mapsection.style.position = 'sticky';
      mapsection.style.top = '0';
    } else {
      mapsection.style.position = 'relative';
    }
  };
 
  opendirections = (lat, lng) => {
    console.log(lat,lng);
    window.open(`${directionsURL}&origin=Current+Location&destination=${lat},${lng}`, '_blank');
  }
 
 
  componentDidUpdate(prevProps, prevState) {
    if (this.state.lat !== prevState.lat || this.state.lng !== prevState.lng) {
      if (this.state.lat && this.state.lng) {
        this.loadGoogleMaps(this.state.lat, this.state.lng);
      }
    }
  }
 
  setBackgroundImage = () => {
    // const mainContainer = document.getElementById("mainContainer");
    // mainContainer.className = "background-hotel-page";
  };
 
  render() {
 
    // The API response is object not an array
    const { propertyinfocontents } = this.state;
    if (!propertyinfocontents || Object.keys(propertyinfocontents).length === 0) {
      return<div className="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>;
    }
    const handleNavClick = (event) => {
      // Remove 'active' class from all nav links
      const navLinks = document.querySelectorAll('.nav-link');
      navLinks.forEach(link => link.classList.remove('active'));
   
      // Add 'active' class to the clicked link
      event.target.classList.add('active');
    };
   
    return(
      <>
        <div className="background-property mt-5 border border-3">
          <div className="row mx-5">
            <div className="row search-row d-flex justify-content-center">
              <h4 className="mt-5">Find Best Place, Restaurant, and many more in just one Click</h4>
            </div>
            {/* Breadcrumb Link for Routing */}
            <div className="row mt-5">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-propertydetailview">
                  <li class="breadcrumb-item"><a href="#">BLLLD</a></li>
                  <li class="breadcrumb-item"><a href="#">Property Listings at Selected Location</a></li>
                  <li class="breadcrumb-item active" aria-current="page">{propertyinfocontents.postMyAdProfile.adTitle}</li>
                </ol>
              </nav>
            </div>
            {/* Detailed Info Section */}
            <div className="row mt-5 detail-property-name">
              <div className="Address-headline row">
                <h4 className="col-md-11">{propertyinfocontents.postMyAdProfile.adTitle}</h4>
                <h5 className="col-md-1 text-black"><i class="fa-solid fa-share-nodes"></i></h5>
              </div>
              <div className="Address-details row">
                <h5 className="col-8 ms-0 mt-1">{propertyinfocontents.postMyAdAddress.addressName}</h5>
                <h5 className="location col-md-4 mt-1 text-end" onClick={() => this.opendirections(propertyinfocontents.postMyAdAddress.latitudes, propertyinfocontents.postMyAdAddress.longitudes)}><i className="fa-solid fa-map-location-dot"></i>&nbsp;&nbsp;Get Directions</h5>
              </div>
            </div>
            <div className="row mt-5 moreDetailssection">
              <div className="col-md-7">
                <div className="row">
                  <div id="" className="house-image position-relative ">
                    <span className="badge bg-warning text-dark pill-position m-3 rounded">
                      <i class="fa-solid fa-graduation-cap"></i> Preferred by {propertyinfocontents.postMyAdIdealRoomMate.professionalSituation}
                    </span>
                    <span className="badge text-dark pill-position-sexdata m-2 rounded">
                      {propertyinfocontents.postMyAdIdealRoomMate.sex}
                    </span>
                    {/* <img className="w-100 rounded-4" src={imgAssests.house2} alt="" /> */}
                    <Carousel fade>
                      {propertyinfocontents.postMyAdFiles.fileDetailsList.map((screenshotfile) => {
                        const correctedpath = screenshotfile.filePath.includes("https://") ? screenshotfile.filePath : screenshotfile.filePath.replace("https:/","https://");
                        return(
                          <Carousel.Item key={screenshotfile.fileId}>
                            <div className="carousel-property-image-wrapper rounded-4" style={{ backgroundImage: `url(${correctedpath})`}}></div>
                          </Carousel.Item>
                        )
                      })}
                    </Carousel>
                  </div>
                </div>
                <div className="row mt-5 property-detail-pricing">
                  <p className="start-from ms-0 ">Starts from</p>
                  <h4>€{propertyinfocontents.postMyAdAboutRoom.monthlyRent} /mo*</h4>
                  <p className="denotes ms-0">*Denotes starting price inclusive of taxes. Final prices may vary based on room occupancy, customized services, and additional attributes. For more info visit our BLLLD FAQ Page</p>
                </div>
                <div className="row mt-5 property-details-header">
                  <nav id="navbar-example2" className="navbar bg-body-tertiary mb-3 rounded-4">
                    <ul className="nav nav-pills rounded">
                      <li className="nav-item">
                        <a className="nav-link active" href="#Details" onClick={handleNavClick}>Details</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#Rules" onClick={handleNavClick}>Rules</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#kind" onClick={handleNavClick}>Kind</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#Amenities" onClick={handleNavClick}>Amenities</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#description" onClick={handleNavClick}>Description</a>
                      </li>
                    </ul>
                  </nav>
 
                  <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-root-margin="0px 0px -40%" data-bs-smooth-scroll="true" class="scrollspy-example bg-body-tertiary p-3 rounded-2" tabindex="0">
                    <div id="Details" className="detail-property-amenites mt-3">
                      {this.state.housingTypecontents.map((housingTypecontent) =>
                        (propertyinfocontents.postMyAdHousing.housingId === housingTypecontent.key && <h5 style={{ color: '#135D66' }}>Details of Your <b style={{ color: '#fd656a' }}>{housingTypecontent.value}</b></h5>)
                      )}
                      <div className="d-flex flex-wrap gap-3 mt-3">
                        <button className="btn btn-sm custom-button rounded-4" disabled>Room Count: {propertyinfocontents.postMyAdHousing.totalRoomCount}</button>
                        <button className="btn btn-sm custom-button rounded-4" disabled>Bathroom Count: {propertyinfocontents.postMyAdHousing.totalBathroomCount}</button>
                        <button className="btn btn-sm custom-button rounded-4" disabled>Available Rooms for Rent: {propertyinfocontents.postMyAdHousing.totalRoomRentCount}</button>
                        {this.state.roomDevelopmentcontents.map((roomDevelopmentcontent) =>
                          (propertyinfocontents.postMyAdAboutRoom.roomDevelopmentId === roomDevelopmentcontent.key &&
                            <button className="btn btn-sm custom-button rounded-4" disabled>{roomDevelopmentcontent.value}</button>
                          )
                        )}
                      <button className="btn btn-sm custom-button rounded-4" disabled>Room Area: {propertyinfocontents.postMyAdAboutRoom.roomArea} m<sup>2</sup></button>
                    </div>
                    </div>
                    <hr className="horizontalline" />
                    <div id="Rules" className="detail-property-amenites mt-1">
                      <h5 style={{ color: '#135D66' }}>Rules of Accommodation</h5>
                      <div className="d-flex flex-wrap gap-3 mt-3">
                        {this.state.sharedhousingRulescontents.map((sharedhousingRulescontent) =>
                          (propertyinfocontents.postMyAdIdealRoomMate.saRuleList.includes(sharedhousingRulescontent.key) && <button className="btn btn-sm custom-button rounded-4"disabled>{sharedhousingRulescontent.value}</button>)
                        )}
                        {
                          ((!propertyinfocontents.postMyAdAboutRoom.isChargesNotIncluded ) && <button className="btn btn-sm custom-button rounded-4"disabled><i class="fa-solid fa-check"></i>&nbsp;Charges Included</button>)
                        }
                      </div>
                    </div>
                    <hr className="horizontalline" />
                    <div id="kind" className="detail-property-amenites mt-3">
                      <h5 className="Available1" style={{ color: '#135D66' }}>Available kind</h5>
                      <div className="d-flex flex-wrap gap-3 mt-3 ">
                        {this.state.roomkindcontents.map((roomkindcontent) =>
                          propertyinfocontents.postMyAdAboutRoom.roomKindId === roomkindcontent.key && <button className="btn btn-sm custom-button rounded-4"disabled><i class="fa-solid fa-people-arrows"></i>&nbsp;{roomkindcontent.value}</button>                
                        )}
                      </div>
                    </div>
                    <hr className="horizontalline"/>
                    <div id="Amenities" className="detail-property-amenites mt-3">
                      <h5 style={{ color: '#135D66' }}>Amenties</h5>
                      <div class="d-flex flex-wrap gap-3 mt-3">
                        {/* {propertyinfocontents.postMyAdHousing.amenityIdList.map((amenityID) =>
                          this.state.amenitieslistcontents.filter((amenity) => amenity.key === amenityID).map((filteredamenity) =>
                              <button className="btn btn-sm btn-outline-dark rounded-4">{filteredamenity.value}</button>
                            )
                        )} */}
                        {this.state.amenitieslistcontents.map((amenitieslistcontent) =>
                         ((propertyinfocontents.postMyAdHousing.amenityIdList.includes(amenitieslistcontent.key)) &&<button className="btn btn-sm custom-button rounded-4"disabled>{amenitieslistcontent.value}</button>)  
                        )}
                      </div>
                    </div>
                    <hr className="horizontalline" />
                    <div id="description" className="mt-3">
                      <h5 style={{ color: '#135D66' }}>Description</h5>
                      <p className="ms-0">{propertyinfocontents.postMyAdProfile.description}</p>
                    </div>
                  </div>
                </div>
                <hr className="horizontalline" />
                <div className="row mt-0 property-virtualexp">
                  <p className="ms-0">3D Virtual Experience Center</p>
                  {/* <img className="w-50 rounded-4" src={imgAssests.image404} alt="virtualexp" /> */}
                  <div className="ratio ratio-16x9 w-75">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/B4o8PvcqHC4?si=qR6E8kUhA6y8H5zw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
              {/* Google maps */}
              <div className="col-md-5">
                <div id="map" ref={this.mapRef} className="map-section rounded-3"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
 
 
       
export default withRouter(HotelInfo);
 
{/* <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true"aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="https://bllldblobstoragenonprod.blob.core.windows.net/bllldcontainer/501/house1.jpg" className="d-block w-100" alt="House 1"/>
    </div>
    <div className="carousel-item active">
      <img src="https://bllldblobstoragenonprod.blob.core.windows.net/bllldcontainer/501/house2.jpg" className="d-block w-100" alt="House 1"/>
    </div>
    <div className="carousel-item active">
      <img src="https://bllldblobstoragenonprod.blob.core.windows.net/bllldcontainer/501/house3.jpg" className="d-block w-100" alt="House 1"/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button  className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"  data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div> */}
 
 
 