import React from "react";
import { Outlet, Link } from "react-router-dom";
import "./Layout.css";
import { imgAssests } from "../../assets/imgAssests/assests";
import Footer from '../Footer/Footer'
import { connect } from "react-redux";
import {loginSelector} from "../../state/selectors/login.selector"
import {setLogoutStatus,clearLoggedUserData} from "../../state/actions/login.status"


class Layout extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
    };
  }

   handleLogout = () => {
    this.props.dispatch(setLogoutStatus())
    this.props.dispatch(clearLoggedUserData())
  };

  render() {
    return (
      <div>
        <div className="row mainContainer">          
          <nav className="navbar navbar-expand-lg bg-transparent lander-page">
                {/* Add Phone number */}
                <Link className="nav-brand" to="/">
                <img src={imgAssests.brandlogo} alt="brandLogo" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                      <Link className="nav-link" to="/">HOME</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/help">FAQ</Link>
                    </li>
                    <li className="nav-item">
                      {this.props.LoginStatus ? (                      
                        <button className="nav-link" onClick={this.handleLogout} type="button">LOG OUT</button>
                      ) : (
                        <Link className="nav-link" to="/login">LOG IN</Link>
                      )}
                    </li>
                    <li className="nav-item"> 
                    <Link className="nav-link ad-button" to="/post-your-ads">POST YOUR AD</Link>

                    {/* {this.props.LoginStatus ? (                      
                        <Link className="nav-link ad-button" to="/post-your-ads">POST YOUR AD</Link>
                      ) : (
                        <Link title="Please sign to post ads" className="nav-link ad-button">POST YOUR AD</Link>
                      )}                    */}
                    </li>
                    {/* <li className="nav-item"> 
                    <Link className="nav-link" to="/address-confirmation">Address Confirmation</Link>
                    </li> */}
                    <li className="nav-item">
                      {this.props.LoginStatus ? (                      
                        <Link className="nav-link ad-button" to="/viewadslist">VIEW MY ADS</Link>
                      ) : (
                        <></>
                        // <Link title="Please sign to View ADS List" className="nav-link ad-button">View My Ads</Link>
                      )}                   
                    {/* <Link className="nav-link ad-button" to="/viewadslist">View My Ads</Link> */}
                    </li>
                    
                  </ul>
              </div>
          </nav>
            <hr className="top-line" />
        </div>
        <>
        <Outlet />
        </>
        <footer className="row ">
            <Footer/>
        </footer>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  LoginStatus:loginSelector(state)
});
export default connect(mapStateToProps)(Layout)

