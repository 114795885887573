import React from "react";
import "./viewadslist.css";
import { Link } from "react-router-dom";
import { withRouter } from "../../hooks/useRouteHooks";
import { DeletePostMyAd, GetAdsList } from "../../api/apiLoader";
import { connect } from "react-redux";
import { loggedinUserIdSelector } from "../../state/selectors/login.selector";

class ViewAdsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewadslistform: [],
            showSuccessMessage: false, // State to manage the success message
        };
    }

    componentDidMount() {
        const { userId } = this.props.LoggedInUserId;

        GetAdsList(userId)
            .then((response) => {
                const result = response.data.myAdList;
                // Sort in descending order based on postMyAdId
                result.sort((a, b) => b.postMyAdId - a.postMyAdId);
                this.setState({ viewadslistform: result });
                console.log(result);
            })
            .catch((error) => {
                console.log("There was an error fetching the data!", error);
            });
    }

    handleDelete = (postMyAdId) => {
        DeletePostMyAd(postMyAdId)
            .then((response) => {
                this.setState((prevState) => ({
                    viewadslistform: prevState.viewadslistform.filter((ad) => ad.postMyAdId !== postMyAdId),
                    showSuccessMessage: true, // Show success message
                }));

                // Hide the success message after 2 seconds
                setTimeout(() => {
                    this.setState({ showSuccessMessage: false });
                }, 3000);
            })
            .catch((error) => {
                console.log("There was an error deleting the ad!", error);
            });
    };

    render() {
        return (
            <div className="row mt-5 justify-content-center">
                <div className="col-10 col-md-8">
                    <h3 className="mb-4 text-center text-light">View Ads List</h3>
                    
                    {this.state.showSuccessMessage && (
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="alert alert-success text-center d-inline-block">
                                Your ad is deleted successfully.
                            </div>
                        </div>
                    )}

                    <div className="table-container row p-3 rounded mx-auto d-block pt-3 text-center mt-5">
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th className="col-1 text-center">S.No</th>
                                    <th className="col-1 text-center">ID</th>
                                    <th className="col-7 text-center">Address</th>
                                    <th className="col-3 text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.viewadslistform.map((ad, index) => (
                                    <tr key={ad.postMyAdId}>
                                        <td className="text-center">{index + 1}</td>
                                        <td className="text-center">{ad.postMyAdId}</td>
                                        <td className="text-start">{ad.addressName}</td>
                                        <td className="actions">
                                            <Link className="action-btn btn-link views" to={`/viewmyads/${ad.postMyAdId}`} title="View">
                                                <i className="fa-regular fa-eye"></i>
                                            </Link>
                                            <Link className="action-btn btn-link edit" to={`/postadsupdate/${ad.postMyAdId}`} title="Edit">
                                                <i className="fa-regular fa-pen-to-square"></i>
                                            </Link>
                                            <button
                                                className="action-btn btn-link delete"
                                                title="Delete"
                                                onClick={() => this.handleDelete(ad.postMyAdId)}
                                            >
                                                <i className="fa-solid fa-trash-can"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    LoggedInUserId: loggedinUserIdSelector(state),
});

export default connect(mapStateToProps)(withRouter(ViewAdsList));
