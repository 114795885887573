import UserLoginStatus from '../actionTypes/login.actiontype'

export function setLoginStatus() {
    return async (dispatch) => {
        dispatch({
            type: UserLoginStatus.SET_LOGIN_STATUS,
            data:true
        })
    }
}

export function setLogoutStatus() {
    return async (dispatch) => {
        dispatch({
            type: UserLoginStatus.SET_LOGOUT_STATUS,
            data:false
        })
    }
}
export function setLoggedUserData(userData) {
    return async (dispatch) => {
        dispatch({
            type: UserLoginStatus.SET_LOGGED_USER_DATA,
            data:userData
        })
    }
}
export function clearLoggedUserData() {
    const userData = {
        userId:0, firstName:'', lastName:'', emailId:'', mobile:''
      };
    return async (dispatch) => {
        dispatch({
            type: UserLoginStatus.SET_LOGGED_USER_DATA,
            data:userData
        })
    }
}