import React from "react";
import { connect } from "react-redux";

import { PanelList } from '../../constant/PostAdsPanelList'
import { PostHouseDetails } from '../../api/apiLoader'
 
class Housing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            HousingInfo: {
                HousingType: 0,
                TotalRooms: 0,
                BathRooms: 0,
                RoomsforRent: 0,
                Amenities: [],
            },
            ValidateErrorMessage: {
                HousingType: "",
                TotalRooms: "",
                BathRooms: "",
                RoomsforRent: "",
                Amenities: "",
            },
        }
    }
    handleHouseChange = (event) => {
        let { HousingInfo, ValidateErrorMessage } = this.state;
        HousingInfo.HousingType = Number(event.target.value);
        if (event.target.name === 'HousingType') {
            if (Number(event.target.value) === 0) {
                ValidateErrorMessage.HousingType = "Please select the house type";
            }
            else {
                ValidateErrorMessage.HousingType = ""
            }
        }
        this.setState({
            HousingInfo: { ...HousingInfo },
            ValidateErrorMessage: { ...ValidateErrorMessage }
        });
    }
 
    OnChangeValidateRoomDetail = (field) =>
    {
        const { HousingInfo, ValidateErrorMessage } = this.state;
        const { TotalRooms, BathRooms, RoomsforRent } = HousingInfo;  
 
        if (field === 'TotalRooms' || field === 'RoomsforRent') {
            if (TotalRooms === 0 && field === 'TotalRooms') {
                ValidateErrorMessage.TotalRooms = "Please enter the available room";
            }
            else if (TotalRooms > 0 && field === 'TotalRooms') {
                ValidateErrorMessage.TotalRooms = "";
            }
            if (RoomsforRent === 0 && field === 'RoomsforRent') {
                ValidateErrorMessage.RoomsforRent = "Please enter the available room for rent";
            }
            else if (RoomsforRent > 0 && field === 'RoomsforRent') {
                ValidateErrorMessage.RoomsforRent = "";
            }
            if (RoomsforRent>0 && TotalRooms> 0 && RoomsforRent > TotalRooms){
                ValidateErrorMessage.RoomsforRent = "Available room for rent should not be greater than total rooms";
            }
            else if (RoomsforRent>0 && TotalRooms> 0 && RoomsforRent > TotalRooms){
                ValidateErrorMessage.RoomsforRent = "";
            }
        }
        else if (field === 'BathRooms') {
            if (BathRooms === 0) {
                ValidateErrorMessage.BathRooms = "Please enter the available bath room";
            }
            else {
                ValidateErrorMessage.BathRooms = ""
            }
        }
        (async () => {
            this.setState({ ValidateErrorMessage: { ...ValidateErrorMessage } })
        })();
    }
 
    handleDecrement = (field) => {
        this.setState((prevState) => {
            let HousingInfo = { ...prevState.HousingInfo };
            HousingInfo[field] = Math.max(HousingInfo[field] - 1, 0);
            return { HousingInfo };
        }, () => {
           this.OnChangeValidateRoomDetail(field)
        });
    };
    //Increment and Decrement Functions
    handleIncrement = (field) => {
        this.setState((prevState) => {
            let HousingInfo = { ...prevState.HousingInfo };
            HousingInfo[field] += 1;
            return { HousingInfo };
        }, () => {
            this.OnChangeValidateRoomDetail(field)
        });
    };
    onClickContinue = (panel) => {
        const { HousingInfo } = this.state;
        const isvalidHouseInfo = this.isValidHouseInfo(HousingInfo)
        if (isvalidHouseInfo) {
            this.setState({ isLoading: true })
            const { HousingType, TotalRooms, BathRooms, RoomsforRent, Amenities } = HousingInfo;
            const payload = {
                postMyAdId: this.props.PostAdsId,
                housingTypeId: HousingType,
                totalRoomCount: TotalRooms,
                totalBathroomCount: BathRooms,
                totalRoomRentCount: RoomsforRent,
                amenityIdList: Amenities
            }
            PostHouseDetails(payload).then(response => {
                const { isSuccess, message, result } = response.data;
                if (isSuccess) {
                    this.setState({ isLoading: false }, () => {
                        this.props.callbackNavigatePanel(panel)
                    })
                }
            }).catch(err => {
                console.log(err)
            })
        }
        else {
            return isvalidHouseInfo;
        }
    }
    isValidHouseInfo = (Housedata) => {
        let isValid = true;
        const { HousingType, TotalRooms, BathRooms, RoomsforRent, Amenities } = Housedata;
        const { ValidateErrorMessage } = this.state;
        if (HousingType === 0) {
            ValidateErrorMessage.HousingType = "Please select the house type";
            isValid = false;
        }
        else {
            ValidateErrorMessage.HousingType = ""
        }
        if (TotalRooms === 0) {
            ValidateErrorMessage.TotalRooms = "Please enter the available room";
            isValid = false;
        }
        else {
            ValidateErrorMessage.TotalRooms = ""
        }
        if (BathRooms === 0) {
            ValidateErrorMessage.BathRooms = "Please enter the available bath room";
            isValid = false;
        }
        else {
            ValidateErrorMessage.BathRooms = ""
        }
        if (RoomsforRent === 0) {
            ValidateErrorMessage.RoomsforRent = "Please enter the available room for rent";
            isValid = false;
        }
        else {
            ValidateErrorMessage.RoomsforRent = ""
        }
        if (RoomsforRent > TotalRooms) {
            ValidateErrorMessage.RoomsforRent = "Available room for rent should not be greater than total rooms";
            isValid = false;
        }
        if (Amenities.length === 0) {
            ValidateErrorMessage.Amenities = "Please select the amenities details";
            isValid = false;
        }
        else {
            ValidateErrorMessage.Amenities = ""
        }
        this.setState({ ValidateErrorMessage: { ...ValidateErrorMessage } })
        return isValid;
    }
    handleAmenityToggle = (value) => {
        let { HousingInfo,ValidateErrorMessage } = this.state;
        let Amenities = [...HousingInfo.Amenities];
        let amenitiesItem = Amenities.find((e) => e === Number(value));
        if (amenitiesItem) {
            Amenities = Amenities.filter((e) => e !== Number(value));
        }
        else {
            Amenities.push(value)
        }
       
        if (Amenities.length === 0) {
            ValidateErrorMessage.Amenities = "Please select the amenities details";
        }
        else {
            ValidateErrorMessage.Amenities = ""
        }
 
        HousingInfo.Amenities = [...Amenities];
        this.setState({ ...HousingInfo,...ValidateErrorMessage})
    }
 
    render() {
        const { ValidateErrorMessage, HousingInfo, isLoading } = this.state;
        return (
            <>
                <div className="row rounded d-flex justify-content-center">
                    <div className="col">
                        <h2 className="section-title mt-3 m-2 pb-2">Housing</h2>
                    </div>
                    <div className="row housingform">
                        <div className="col-4">
                            <div className="form-group">
                                <label className="text-start pb-2">Housing Type <span className="text-danger">*</span></label>
                                <select className="form-control form-select" onChange={this.handleHouseChange} name="HousingType">
                                    <option key="housetype0" value="0">Please choose house type</option>
                                    {this.props.housingTypecontents.map((housingTypecontent) => (
                                        <option key={housingTypecontent.value} value={housingTypecontent.key}>{housingTypecontent.value}</option>
                                    ))}
                                </select>
                                {
                                    ValidateErrorMessage.HousingType && <p className="text-danger Housingerror-msg">{ValidateErrorMessage.HousingType}</p>
                                }
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-group text-center">
                                <label className="pb-2">Total Room(s)</label>
                                <div className="input-group d-flex justify-content-center">
                                    <button className="btn btn-outline-secondary btn-md custom-disabled" onClick={() => this.handleDecrement("TotalRooms")}>-</button>
                                    <button className="btn btn-outline-secondary btn-md custom-disabled" disabled>{HousingInfo.TotalRooms}</button>
                                    {/* <input type="text" className="form-control text-center" value={HousingInfo.TotalRooms} readOnly /> */}
                                    <button className="btn btn-outline-secondary btn-md custom-disabled" onClick={() => this.handleIncrement("TotalRooms")}>+</button>
                                </div>
                                {
                                    ValidateErrorMessage.TotalRooms && <p className="text-danger Housingerror-msg">{ValidateErrorMessage.TotalRooms}</p>
                                }
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group text-center">
                                <label className=" pb-2 ">No Bathroom(s)</label>
                                <div className="input-group d-flex justify-content-center">
                                    <button className="btn btn-outline-secondary btn-md custom-disabled" onClick={() => this.handleDecrement("BathRooms")}>-</button>
                                    <button className="btn btn-outline-secondary btn-md custom-disabled" disabled>{HousingInfo.BathRooms}</button>
                                    {/* <input type="text" className="form-control text-center" value={HousingInfo.BathRooms} readOnly /> */}
                                    <button className="btn btn-outline-secondary btn-md custom-disabled" onClick={() => this.handleIncrement("BathRooms")}>+</button>
                                </div>
                                {
                                    ValidateErrorMessage.BathRooms && <p className="text-danger Housingerror-msg">{ValidateErrorMessage.BathRooms}</p>
                                }
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group text-center">
                                <label className="pb-2">No Rooms for Rent</label>
                                <div className="input-group d-flex justify-content-center">
                                    <button className="btn btn-outline-secondary btn-md custom-disabled" onClick={() => this.handleDecrement("RoomsforRent")}>-</button>
                                    <button className="btn btn-outline-secondary btn-md custom-disabled" disabled>{HousingInfo.RoomsforRent}</button>
                                    {/* <input type="text" className="form-control text-center" value={HousingInfo.RoomsforRent} readOnly /> */}
                                    <button className="btn btn-outline-secondary btn-md custom-disabled" onClick={() => this.handleIncrement("RoomsforRent")}>+</button>
                                </div>
                                {
                                    ValidateErrorMessage.RoomsforRent && <p className="text-danger Housingerror-msg">{ValidateErrorMessage.RoomsforRent}</p>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col p-2">
                                <label className="text-center mt-3 pb-1">Amenities</label>
                                <div className="d-flex flex-wrap bd-highlight mb-3">
                                    {
                                        this.props.amenitieslistcontents.map((amenities) => (
 
                                            <div className="p-2 bd-highlight">
                                                <button key={amenities.key} className={`btn btn-sm amenites-border amenties-btn ${HousingInfo.Amenities && HousingInfo.Amenities.includes(amenities.key) ? 'border-success border-opacity-75' : 'border border-2'}`}
                                                    onClick={() => this.handleAmenityToggle(amenities.key)}>
                                                    {amenities.value}
                                                </button>
 
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                    ValidateErrorMessage.Amenities && <p className="text-danger Housingerror-msg">{ValidateErrorMessage.Amenities}</p>
                                }
                            </div>
                        </div>
                        <div className="row justify-content-center p-3">
                            <div className="col-auto">
                                {
                                    isLoading && <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }
                                <input type="button" value={"Continue"} className="btn btn-continue"
                                    onClick={() => this.onClickContinue(PanelList.ABOUT_THE_ROOM)}></input>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
 
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(Housing)
 
 
 