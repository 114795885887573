import React from "react";
import { connect } from "react-redux";
import { PostYourProfileDetails, PostYourProfileDetailsUpdate } from '../../api/apiLoader';
// import { loggedinUserDataSelector, loggedinUserIdSelector } from "../../state/selectors/login.selector";
class YourProfileEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            YourprofileInfo: {
                aboutyou: "0",
                phone: "",
                firstname: "",
                name: "",
                phonevisibleStatus: false,
                adtitle: "",
                description: "",
            },
            ValidateErrorMessage: {
                aboutyou: "",
                phone: "",
                firstname: "",
                name: "",
                phonevisibleStatus: "",
                adtitle: "",
                description: "",
            }
        }
    }
    componentDidMount() {
        console.log(this.props.postMyAdProfile)
        const {postMyAdProfile} = this.props;
        this.setState({
            YourprofileInfo: {
                aboutyou: postMyAdProfile.postMyAdRoleId,  
                phone: postMyAdProfile.phoneNumber,
                firstname: postMyAdProfile.firstName,
                name: postMyAdProfile.lastName,
                phonevisibleStatus: postMyAdProfile.doNotShowPhoneNumber,
                adtitle: postMyAdProfile.adTitle,
                description: postMyAdProfile.description
            }
        });
        

    }
    onClickContinue = (panel) => {

        const { YourprofileInfo } = this.state;
        const isValid = this.isValidYourProfileData(YourprofileInfo);
        if (isValid) {
            const { aboutyou, phone, firstname, name, phonevisibleStatus, adtitle, description } = YourprofileInfo;

            const payload = {
                postMyAdId: this.props.PostAdsId,
                postMyAdRoleId: Number(aboutyou),
                firstName: firstname.trim(),
                lastName: name,
                phoneNumber: phone,
                doNotShowPhoneNumber: phonevisibleStatus,
                adTitle: adtitle.trim(),
                description: description.trim()
            }
            this.setState({ isLoading: true })
            PostYourProfileDetailsUpdate(payload).then(response => {
                const { isSuccess, message, result: PostAdsId } = response.data;
                if (isSuccess) {
                    this.setState({ isLoading: false }, () => {
                        this.props.callbackNavigatePanel(panel)
                    })
                }
            }).catch(err => {
                console.log(err)
            })
        }

    }

    isValidYourProfileData = (profileData) => {
        let isValid = true;
        const { aboutyou, phone, firstname, name,
            adtitle, description } = profileData;
        const { ValidateErrorMessage } = this.state;
        if (aboutyou === "0") {
            ValidateErrorMessage.aboutyou = "Please select the profile";
            isValid = false;
        }
        else {
            ValidateErrorMessage.aboutyou = "";
        }
        if (phone.trim() === "") {
            ValidateErrorMessage.phone = "Please enter the phone number";
            isValid = false;
        }
        else {
            ValidateErrorMessage.phone = "";
        }
        if (firstname.trim() === "") {
            ValidateErrorMessage.firstname = "Please enter the first name";
            isValid = false;
        }
        else {
            ValidateErrorMessage.firstname = "";
        }
        if (firstname.trim() === "") {
            ValidateErrorMessage.firstname = "Please enter the first name";
            isValid = false;
        }
        else {
            ValidateErrorMessage.firstname = "";
        }
        if (name.trim() === "") {
            ValidateErrorMessage.name = "Please enter the name";
            isValid = false;
        }
        else {
            ValidateErrorMessage.name = "";
        }
        if (adtitle.trim() === "") {
            ValidateErrorMessage.adtitle = "Please enter Ads title";
            isValid = false;
        }
        else {
            ValidateErrorMessage.adtitle = "";
        }
        if (description.trim() === "") {
            ValidateErrorMessage.description = "Please enter description";
            isValid = false;
        }
        else {
            ValidateErrorMessage.description = "";
        }
        this.setState({ ValidateErrorMessage: { ...ValidateErrorMessage } })
        return isValid;
    }
    // adtitle, description
    handleYourProfileInfoChange = (event) => {
        const { YourprofileInfo, ValidateErrorMessage } = this.state;
        const tempdata = { [event.target.name]: event.target.value }

        if (event.target.name === "aboutyou") {
            if (event.target.value === "0") {
                ValidateErrorMessage.aboutyou = "Please select the profile";
            }
            else {
                ValidateErrorMessage.aboutyou = "";
            }
        }
        if (event.target.name === "phone") {
            if (event.target.value.trim() === "") {
                ValidateErrorMessage.phone = "Please enter the phone number";
            }
            else {
                ValidateErrorMessage.phone = "";
            }
        }
        if (event.target.name === "firstname") {
            if (event.target.value.trim() === "") {
                ValidateErrorMessage.firstname = "Please enter the first name";
            }
            else {
                ValidateErrorMessage.firstname = "";
            }
        }
        if (event.target.name === "name") {
            if (event.target.value.trim() === "") {
                ValidateErrorMessage.name = "Please enter the name";
            }
            else {
                ValidateErrorMessage.name = "";
            }
        }
        if (event.target.name === "adtitle") {
            if (event.target.value.trim() === "") {
                ValidateErrorMessage.adtitle = "Please enter Ads title";
            }
            else {
                ValidateErrorMessage.adtitle = "";
            }
        }
        if (event.target.name === "description") {
            if (event.target.value.trim() === "") {
                ValidateErrorMessage.description = "Please enter description";
            }
            else {
                ValidateErrorMessage.description = "";
            }
        }

        let profileinfo = { ...YourprofileInfo, ...tempdata };
        this.setState({ YourprofileInfo: profileinfo });
    }
    handlePhoneVisibleStatusChange = (event) => {
        const { checked } = event.target;
        const { YourprofileInfo } = this.state;
        YourprofileInfo[event.target.name] = checked;
        this.setState({ YourprofileInfo: { ...YourprofileInfo } })
    };
    render() {
        const { YourprofileInfo, ValidateErrorMessage,isLoading } = this.state;
        return (
            <>
                <div className="your-profile">
                    <h2>Your Profile</h2>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group text-start">
                            <label className="text-dark">About You<span className="text-danger"> *</span></label>
                            <select className="form-control" value={YourprofileInfo.aboutyou} name="aboutyou"
                                onChange={this.handleYourProfileInfoChange}>
                                <option key={"Profile0"} value={"0"}>{"Please select the age"}</option>
                                {this.props.rolelistcontents.map((rolelistcontent) => (
                                    <option key={rolelistcontent.value} value={rolelistcontent.key}>{rolelistcontent.value}</option>
                                ))}
                            </select>
                            {
                                ValidateErrorMessage.aboutyou !== "" && <p className="text-danger">{ValidateErrorMessage.aboutyou}</p>
                            }
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group text-start">
                            <label className="text-dark">Phone<span className="text-danger"> *</span></label>
                            <input type="number" className="form-control" value={YourprofileInfo.phone} name="phone"
                                onChange={this.handleYourProfileInfoChange} autoComplete="new-password" />
                            {
                                ValidateErrorMessage.phone !== "" && <p className="text-danger">{ValidateErrorMessage.phone}</p>
                            }
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="form-group text-start">
                            <label className="text-dark">First Name<span className="text-danger"> *</span></label>
                            <input type="text" className="form-control" value={YourprofileInfo.firstname} name="firstname"
                                onChange={this.handleYourProfileInfoChange} autoComplete="new-password" />
                            {
                                ValidateErrorMessage.firstname !== "" && <p className="text-danger">{ValidateErrorMessage.firstname}</p>
                            }
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group text-start">
                            <label className="text-dark">Name<span className="text-danger"> *</span></label>
                            <input type="text" className="form-control" value={YourprofileInfo.name} name="name" onChange={this.handleYourProfileInfoChange} />
                            {
                                ValidateErrorMessage.name !== "" && <p className="text-danger">{ValidateErrorMessage.name}</p>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="mt-2">
                            <input type="checkbox" className="form-check-input" checked={YourprofileInfo.phonevisibleStatus} name="phonevisibleStatus" onChange={this.handlePhoneVisibleStatusChange} />
                            <label className="form-check-label ms-2">
                                I don't want my phone to be Visible(Note that you will receive less contact)
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="description mt-3">
                            <h2>Description</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label className="text-dark">Ad title <span className="text-danger"> *</span></label>
                            <input type="text" placeholder="New Tenents" className="form-control" value={YourprofileInfo.adtitle} name="adtitle" onChange={this.handleYourProfileInfoChange} />
                            {
                                ValidateErrorMessage.adtitle !== "" && <p className="text-danger">{ValidateErrorMessage.adtitle}</p>
                            }
                        </div>
                        <div className="col">
                            <div className="form-group text-start">
                                <label className="text-dark" htmlFor="roomDescription">Description of your accommodation<span className="text-danger"> *</span></label>
                                <textarea
                                    id="description"
                                    className="form-control"
                                    placeholder="Describe the room here..."
                                    value={YourprofileInfo.description}
                                    name="description"
                                    onChange={this.handleYourProfileInfoChange}
                                >
                                </textarea>
                                {
                                    ValidateErrorMessage.description !== "" && <p className="text-danger">{ValidateErrorMessage.description}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center p-3">
                    <div className="col-auto">
                        {
                            isLoading && <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        }
                        <input type="button" value={"Update"} className="btn btn-continue"
                            onClick={() => this.onClickContinue('AdsList')}></input>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => ({
});
export default connect(mapStateToProps)(YourProfileEdit)
