import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';

import Layout from "./component/Layout/Layout";
import Home from "./component/Home/Home";
import Help from "./component/Help/Help";
import NoPage from "./component/NoPage";
import Login from "./component/Login";
import Signup from "./component/Signup/signup";
import HotelList from "./component/HotelList/HotelList";
import HotelInfo from "./component/HotelList/HotelInfo";
import PostYourAds from './component/PostAds/PostyourAds'
import LocationWithGooglemap from './component/PostAds/LocationWithGooglemap'
import store from './state/stores/store';
import ViewAdsList from "./component/viewadslist/viewadslist";
import ViewMyAds from "./component/viewmyads/viewmyads";
import Postadsupdate from "./component/postAdsUpdate/postadsupdate";
import ForgotPassword from "./component/ForgotPassword/ForgotPassword";

export default function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="help" element={<Help />} />
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="post-your-ads" element={<PostYourAds />} />
            <Route path="hotel-list" element={<HotelList />} />
            <Route path="hotel-list/:option/:countryname/:subname" element={<HotelList />} />
            <Route path="hotel-info/:HotelId" element={<HotelInfo />} />
            <Route path="address-confirmation" element={<LocationWithGooglemap />} />
            {/* <Route path="viewmyads" element={<ViewMyAds />} /> */}
            <Route path="/viewmyads/:postMyAdId" element={<ViewMyAds />} />
            <Route path="viewadslist" element={<ViewAdsList />} />
            <Route path="*" element={<NoPage />} />
            <Route path="postadsupdate/:postMyAdId" element={<Postadsupdate />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

