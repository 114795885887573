import React from "react";
import { connect } from "react-redux";
import { PanelList } from '../../constant/PostAdsPanelList'
import { PostCurrentRoommatesDetails } from '../../api/apiLoader'
import { GenderList, ProfessionalList } from "../../constant/PostAdsPanelList"

class CurrentRoommates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            CurrentRoommatesInfo: {
                numberofRoommates: 0,
                sex: "0",
                professionalSituation: "0",
                ageFrom: "",
                ageUpto: "",
                sharedhousingRulescontents: [],
            },
            ValidateErrorMessage: {
                numberofRoommates: "",
                sex: "",
                professionalSituation: "",
                ageFrom: "",
                ageUpto: "",
                sharedhousingRulescontents: [],
            },
        }
    }   
  
  componentDidMount()
  {
        const { CurrentRoommatesInfo } = this.state;
        CurrentRoommatesInfo.sharedhousingRulescontents = this.props.sharedhousingRulescontents
        this.setState({...CurrentRoommatesInfo})
  }
    handleDecrementCurrentRoommate = (field) => {
        this.setState((prevState) => {
            let CurrentRoommatesInfo = { ...prevState.CurrentRoommatesInfo };
            CurrentRoommatesInfo[field] = Math.max(CurrentRoommatesInfo[field] - 1, 0);
            return { CurrentRoommatesInfo };
        }, () => {
            const { numberofRoommates } = this.state.CurrentRoommatesInfo
            const { ValidateErrorMessage } = this.state;
            if (Number(numberofRoommates) === 0) {
                ValidateErrorMessage.numberofRoommates = "Please enter the number of roommates";
            } else {
                ValidateErrorMessage.numberofRoommates = ""
            }
            (async () => {
                await this.setState({ ValidateErrorMessage: { ...ValidateErrorMessage } });
            })();
        });
    };
    handleIncrementCurrentRoommate = (field) => {
        this.setState((prevState) => {
            let CurrentRoommatesInfo = { ...prevState.CurrentRoommatesInfo };
            CurrentRoommatesInfo[field] += 1;
            return { CurrentRoommatesInfo };
        }, () => {
            const { numberofRoommates } = this.state.CurrentRoommatesInfo
            const { ValidateErrorMessage } = this.state;
            if (Number(numberofRoommates) === 0) {
                ValidateErrorMessage.numberofRoommates = "Please enter the number of roommates";
            } else {
                ValidateErrorMessage.numberofRoommates = ""
            }
            (async () => {
                await this.setState({ ValidateErrorMessage: { ...ValidateErrorMessage } });
            })();
        });
    };
    handleRoommateInfoChange = (event) => {
        const { ValidateErrorMessage, CurrentRoommatesInfo } = this.state;

        const tempdata = { [event.target.name]: event.target.value }

        if (event.target.name === "sex") {
            if (event.target.value === "0") {
                ValidateErrorMessage.sex = "Please select the sex";
            }
            else {
                ValidateErrorMessage.sex = "";
            }
        }
        if (event.target.name === "professionalSituation") {
            if (event.target.value === "0") {
                ValidateErrorMessage.professionalSituation = "Please select the professional situation";
            }
            else {
                ValidateErrorMessage.professionalSituation = "";
            }
        }
        if (event.target.name === "ageFrom") {
            if (event.target.value === "") {
                ValidateErrorMessage.ageFrom = "Please select the age from ";
            }
            else {
                ValidateErrorMessage.ageFrom = "";
            }
        }
        if (event.target.name === "ageUpto") {
            if (event.target.value === "") {
                ValidateErrorMessage.ageUpto = "Please select the age up to";
            }
            else {
                ValidateErrorMessage.ageUpto = "";
            }
        }
        let temproom = { ...CurrentRoommatesInfo, ...tempdata };
        this.setState({ CurrentRoommatesInfo: { ...temproom }, ...ValidateErrorMessage });
    }
    renderAgeList = () => {
        let ageList = [];
        ageList.push(<option key={`Age0`} value={'0'}>{'Please select the age'}</option>);
        for (let i = 30; i <= 90; i += 5) {
            ageList.push(<option key={`Age${i}`} value={i}>{i}</option>);
        }
        ageList.push(<option key={`Age99`} value={99}>{99}</option>);
        return ageList;
    }
    handleRulesAcceptedChange = (event) => {
        let { CurrentRoommatesInfo } = this.state;
        const { value, checked } = event.target;
        let ruleItem = CurrentRoommatesInfo.sharedhousingRulescontents.find((e) => e.key === Number(value));
        ruleItem.checked = checked;
        this.setState({ ...CurrentRoommatesInfo })
    };
    onClickContinue = (panel) => {
        const { CurrentRoommatesInfo } = this.state;
        const isRoommatesValid = this.isValidRoommatesData(CurrentRoommatesInfo);
        if (isRoommatesValid) {
            const { numberofRoommates, sex, professionalSituation, ageFrom,
                ageUpto, sharedhousingRulescontents } = CurrentRoommatesInfo;
            const saRuleList = sharedhousingRulescontents.filter((e) => e.checked === true).map((e) => e.key)

            const payload = {
                postMyAdId: this.props.PostAdsId,
                idealRoomMateId: 0,
                currentRoommatesCount: numberofRoommates,
                sex,
                professionalSituation: professionalSituation,
                ageFrom: Number(ageFrom),
                ageTo: Number(ageUpto),
                saRuleList
            }
            PostCurrentRoommatesDetails(payload).
                then((response) => {
                    const { isSuccess } = response.data;
                    if (isSuccess) {
                        this.setState({ isLoading: false }, () => {
                           this.props.callbackNavigatePanel(panel)
                        })
                    }
                })
        }
        else 
        {
            return isRoommatesValid;
        }
    }
    isValidRoommatesData = (RoommatesData) => {
        let isValid = true;
        const { numberofRoommates, sex, professionalSituation,
            ageFrom, ageUpto, sharedhousingRulescontents } = RoommatesData;
        const { ValidateErrorMessage } = this.state;

        if (Number(numberofRoommates) === 0) {
            ValidateErrorMessage.numberofRoommates = "Please enter the number of roommates";
            isValid = false
        } else {
            ValidateErrorMessage.numberofRoommates = "";
        }
        if (sex === "0") {
            ValidateErrorMessage.sex = "Please select the sex";
            isValid = false
        } else {
            ValidateErrorMessage.sex = "";
        }
        if (professionalSituation === "0") {
            ValidateErrorMessage.professionalSituation = "Please select the professional situation";
            isValid = false
        } else {
            ValidateErrorMessage.professionalSituation = "";
        }
        if (ageFrom === "0") {
            ValidateErrorMessage.ageFrom = "Please select the age from ";
            isValid = false
        } else {
            ValidateErrorMessage.ageFrom = "";
        }
        if (ageUpto === "0") {
            ValidateErrorMessage.ageUpto = "Please select the age up to";
            isValid = false
        } else {
            ValidateErrorMessage.ageUpto = "";
        }
        this.setState({ ValidateErrorMessage: { ...ValidateErrorMessage } })
        return isValid;
    }
    render() {
        const { isLoading, CurrentRoommatesInfo, ValidateErrorMessage } = this.state;
        return (
            <>
                <div className="col">
                    <div className="currentroommate-title section-title">
                        <h2>Current Roommates</h2>
                    </div>
                    <div className="row currentroomateForm">
                        <div className="col-md-8">
                            <div className="form-group col-12">
                                <div className="input-group mb-2">
                                    <p className="me-3">Number of Roommates living in the accommodation</p>
                                    <button className="btn btn-outline-secondary btn-md custom-disabled" onClick={() => this.handleDecrementCurrentRoommate("numberofRoommates")}>-</button>
                                    <button className="btn btn-outline-secondary btn-md custom-disabled" disabled>{CurrentRoommatesInfo.numberofRoommates}</button>
                                    {/* <input type="text" className="form-control text-center" value={CurrentRoommatesInfo.numberofRoommates} name="numberofRoommates" readOnly /> */}
                                    <button className="btn btn-outline-secondary btn-md custom-disabled" onClick={() => this.handleIncrementCurrentRoommate("numberofRoommates")}>+</button>
                                </div>
                                {
                                    ValidateErrorMessage.numberofRoommates && <p className="text-danger">{ValidateErrorMessage.numberofRoommates}</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="Yourideal-roomates-section mt-2">
                        <div className="Youridealroommate-title section-title">
                            <h2>Your Ideal Roommates</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group text-start">
                                <label className="mb-2">Sex<span className="text-danger">*</span></label>
                                <select className="form-control" value={CurrentRoommatesInfo.sex} name="sex" onChange={this.handleRoommateInfoChange}>
                                    <option key='Gender0' value={'0'}>Please select gender</option>
                                    {GenderList.map((gender) => (
                                        <option key={gender} value={gender}>{gender}</option>
                                    ))}
                                </select>
                                {
                                    ValidateErrorMessage.sex && <p className="text-danger">{ValidateErrorMessage.sex}</p>
                                }
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group text-start">
                                <label className="mb-2">Professional Situation<span className="text-danger">*</span></label>
                                <select className="form-control" value={CurrentRoommatesInfo.professionalSituation} name="professionalSituation"
                                    onChange={this.handleRoommateInfoChange}>
                                    <option key='prflist' value={'0'}>Please select professional situation</option>
                                    {ProfessionalList.map((prflist) => (
                                        <option key={prflist} value={prflist}>{prflist}</option>
                                    ))}
                                </select>
                                {
                                    ValidateErrorMessage.professionalSituation && <p className="text-danger">{ValidateErrorMessage.professionalSituation}</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2 age-section">
                        <div className="col-md-3">
                            <div className="">
                                <p className="">Age:</p>
                                <p className="input-group agesection">From</p>
                                {/*ageFrom: "18", ageUpto: "30", */}
                                <input type="number" className="form-control age-from " value={CurrentRoommatesInfo.ageFrom} name="ageFrom" onChange={this.handleRoommateInfoChange}>
                                </input>
                            </div>
                            {/* <div>
                            {
                                ValidateErrorMessage.ageFrom && <p className="text-danger">{ValidateErrorMessage.ageFrom}</p>
                            }
                            </div> */}
                        </div>
                        <div className="col-md-3">
                            <div className="">
                                <p className="invisible">...</p>   
                                <p className="input-group agesection">Upto</p>
                                {/*ageFrom: "18", ageUpto: "30", */}
                                <input type="number" className="form-control  age-upto" value={CurrentRoommatesInfo.ageUpto} name="ageUpto"onChange={this.handleRoommateInfoChange}>
                                    
                                </input>
                            </div>
                            {/* <div>
                            {
                                ValidateErrorMessage.ageUpto && <p className="text-danger">{ValidateErrorMessage.ageUpto}</p>
                            }
                            </div> */}
                        </div>                                        
                        <div className="col"></div>
                    </div>
                    <div className="row housing-title mt-4">
                        <h2 className="section-title ">The rules of your shared accommodation</h2>
                    </div>
                    <div className="row">
                        {this.props.sharedhousingRulescontents.map((rule) => {
                            return (
                                <div className="col">
                                    <div className="form-check text-start mt-1">
                                        <div key={`$ruleId{rule.key}`}>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={rule.checked}
                                                value={rule.key}
                                                onChange={(event) => this.handleRulesAcceptedChange(event)}
                                            />
                                            <label htmlFor="" className="form-check-label">
                                                {rule.value}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="row justify-content-center p-3">
                        <div className="col-auto">
                            {
                                isLoading && <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            }
                            <input type="button" value={"Continue"} className="btn btn-continue"
                                onClick={() => this.onClickContinue(PanelList.YOUR_PROFILE)}></input>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center text-center">
                        <p> </p>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(CurrentRoommates)