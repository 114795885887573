 import React from "react";
import "./ForgotPassword.css";
import { imgAssests } from "../../assets/imgAssests/assests";
import { ForgotPasswordapi, OTPcodeAPI, ResetPasswordAPI } from "../../api/apiLoader";
import { withRouter } from "../../hooks/useRouteHooks";
 
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '',
      otp: '',
      newPassword: '',
      confirmPassword: '',
      accessCode:"",
      error: {
        phoneNumber: '',
        otp: '',
        newPassword: '',
        confirmPassword: '',
      },
      isLoading: false,
      responseMessage: '',
      NetworkError: '',
      isSuccess: false,
      isFailed: false,
      isOTPVerified: false,
      isPasswordReset: false,
      loadingButton: '', // Track which button is loading
      countryCode: '+352',
    };
  }
 
 
  handleChange = (e) => {
    const phoneNumber = e.target.value;
    const phoneRegex = /^[0-9]*$/; // Allow only digits
    if (phoneNumber === "" || phoneRegex.test(phoneNumber)) {
      this.setState({ phoneNumber });
    }
  };
 
  handleCountryCodeChange = (e) => {
    this.setState({ countryCode: e.target.value });
  };
 
  handleOTPChange = (e) => {
    const otp = e.target.value;
    if (/^\d*$/.test(otp)) { // Allow only digits
      this.setState({ otp });
    }
  };
 
  handlePasswordChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
 
  handleSubmit = (e) => {
    e.preventDefault();
    const { phoneNumber } = this.state;
    let isValid = true;
    let error = { phoneNumber: '' };
 
    const phoneFormatRegex = /^[0-9]{10}$/; // Assuming a 10-digit phone number
 
    if (phoneNumber.trim() === "") {
      error.phoneNumber = "Please enter your phone number.";
      isValid = false;
    } else if (!phoneFormatRegex.test(phoneNumber)) {
      error.phoneNumber = "Please enter a valid phone number.";
      isValid = false;
    }
 
    this.setState({ error });
 
    if (isValid) {
      this.setState({ isLoading: true, loadingButton: 'submit' });
   
        ForgotPasswordapi(phoneNumber)
          .then((response) => {
            if (response.data.isSuccess) {
              console.log("OTP sent", response.data);
              this.setState({
                isLoading: false,
                responseMessage: "OTP has been sent to your phone number.",
                isSuccess: true,
                isFailed: false,
                loadingButton: '',
              });
            }
            else{
              this.setState({
                isLoading: false,
                responseMessage: response.data.message || "Number Invalid",
                isFailed: true

              });
 
            }
          })
          .catch((err) => {
            // Handle errors from the API call
            this.setState({
              isLoading: false,
              responseMessage: "Failed to send OTP. Please try again.",
              isSuccess: false,
              isFailed: true,
              loadingButton: '',
            });
            console.error("Error sending OTP:", err);
          });
    }
  }
 
  handleOTPSubmit = (e) => {
    e.preventDefault();
    const { otp, phoneNumber } = this.state;
    let error = { otp: '' };
 
    if (otp.trim() === "" || otp.length !== 4) {
      error.otp = "Please enter a valid 4-digit OTP.";
    } else {
      // Simulate OTP verification
      this.setState({ isLoading: true, loadingButton: 'otp' });
   
      try {
        OTPcodeAPI(phoneNumber, otp)
          .then((response) => {
            console.log(!response.data.isSuccess);
            if (response.data.isSuccess) {
              this.setState({
                isLoading: false,
                isOTPVerified: true,
                responseMessage: "OTP verified successfully!",
                loadingButton: '',
                accessCode: response.data.result,
              });
              console.log(this.state.accessCode);
            }
            else{
              this.setState({
              isLoading: false,
              responseMessage: "Invaild OTP",
              loadingButton: '',
            });
            console.log("Invaild OTP")
 
            }
          })
          .catch((err) => {
            // Handle errors from the API call
            this.setState({
              isLoading: false,
              responseMessage: "Failed to verify OTP. Please try again.",
              loadingButton: '',
            });
            console.error("Error during OTP verification:", err);
          });
      } catch (error) {
        // Handle unexpected errors
        this.setState({
          isLoading: false,
          responseMessage: "An unexpected error occurred. Please try again.",
          loadingButton: '',
        });
        console.error("Unexpected error:", error);
      }
    }
   
 
    this.setState({ error });
  };
 
  handleResetPassword = (e) => {
    e.preventDefault();
    const { newPassword, confirmPassword,accessCode } = this.state;
    let error = { newPassword: '', confirmPassword: '' };
 
    if (newPassword.trim() === "") {
      error.newPassword = "Please enter a new password.";
    }
    if (confirmPassword.trim() === "") {
      error.confirmPassword = "Please confirm your new password.";
    } else if (newPassword !== confirmPassword) {
      error.confirmPassword = "Passwords do not match.";
    }
 
    this.setState({ error });
 
    if (!error.newPassword && !error.confirmPassword) {
      // Simulate password reset
      this.setState({ isLoading: true, loadingButton: 'reset' });
   
      try {
        ResetPasswordAPI(accessCode, newPassword)
          .then((response) => {
            console.log(response.data.isSuccess);
            if (response.data.isSuccess) {
              this.setState({
                isLoading: false,
                isPasswordReset: true,
                responseMessage: "Password has been reset successfully!",
                loadingButton: '',
              });
              setTimeout(() => {
                const { navigate } = this.props.router;
                navigate(`/login`);
              }, 2000);
            }
            
          })
          .catch((err) => {
            // Handle errors in the API response
            this.setState({
              isLoading: false,
              responseMessage: "Error resetting password. Please try again.",
              loadingButton: '',
              isFailed: false,
            });
            console.error("Error resetting password:", err);
          });
      } catch (error) {
        // Handle unexpected errors
        this.setState({
          isLoading: false,
          responseMessage: "An unexpected error occurred. Please try again.",
          loadingButton: '',
          isFailed: true,
        });
        console.error("Unexpected error:", error);
      }
    }
  };
 
  render() {
    const { phoneNumber, otp, newPassword, confirmPassword, error, isLoading, responseMessage, isSuccess, isFailed, isOTPVerified, isPasswordReset, loadingButton, countryCode } = this.state;
 
    return (
      <div className="row justify-content-center">
        <div className="col-md-3 mt-5 mb-3 pt-2 rounded forgot-password-container">
          <form noValidate autoComplete="off" className="text-left" onSubmit={this.handleSubmit}>
            <h1 className="forgot-password-title mb-3 text-center text-dark">Forgot Password</h1>
            <img src={imgAssests.security} alt="Security" className="img-fluid mx-auto d-block mb-3" />
 
            <div className="form-group text-dark">
              <label htmlFor="phoneNumber">
                Phone Number<span className="text-danger">*</span>
              </label>
              {error.phoneNumber && <span className="text-danger">{error.phoneNumber}</span>}
 
              <div className="phone-nmbr input-group">
                <div className="input-group-prepend">
                  <select
                    className="form-select country-code-dropdown"
                    value={countryCode}
                    onChange={this.handleCountryCodeChange}
                    style={{ maxWidth: '90px' }}
                  >
                    <option value="+352">+352</option>
                    <option value="+91">+91</option>
                    <option value="+44">+44</option>
                    <option value="+61">+61</option>
                    {/* Add more country codes as needed */}
                  </select>
                </div>
 
                <input
                  type="tel"
                  className="form-control"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={this.handleChange}
                  autoComplete="off"
                  placeholder="Enter phone number"
                />
              </div>
            </div>
            {isFailed && (
                <div className="alert alert-danger m-2" role="alert">
                  {responseMessage}
                </div>
              )}
 
 
            <div className="forgot-password-button form-group mt-2 rounded text-center">
              {!isSuccess && (
                <button className="btn btn-normal submitbtn" type="submit" disabled={isLoading}>
                  Submit
                  {loadingButton === 'submit' && isLoading && (
                    <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                  )}
                </button>
              )}
             
              {isSuccess && (
                <div className="alert alert-success m-2" role="alert">
                  {responseMessage}
                </div>
              )}
            </div>
          </form>
 
          {isSuccess && !isOTPVerified && (
            <form noValidate autoComplete="off" className="text-left" onSubmit={this.handleOTPSubmit}>
              <div className="form-group text-dark">
                <label htmlFor="otp">OTP<span className="text-danger">*</span></label>
                {error.otp && <span className="text-danger">{error.otp}</span>}
                <input
                  type="text"
                  className="form-control"
                  id="otp"
                  name="otp"
                  value={otp}
                  onChange={this.handleOTPChange}
                  maxLength="6"
                  disabled={isOTPVerified}  
                  autoComplete="off"
                />
              </div>
 
              <div className="otp-button form-group mt-2 rounded text-center">
                {!isOTPVerified && (
                  <button className="btn btn-normal submitbtn" type="submit" disabled={isLoading}>
                    Verify OTP
                    {loadingButton === 'otp' && isLoading && (
                      <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                    )}
                  </button>
                )}
 
                {isOTPVerified && (
                  <div className="alert alert-success m-2" role="alert">
                    {responseMessage}
                  </div>
                )}
              </div>
            </form>
          )}
 
          {/* Reset Password Form */}
          {isOTPVerified && !isPasswordReset && (
            <form noValidate autoComplete="off" className="text-left" onSubmit={this.handleResetPassword}>
              <div className="form-group text-dark">
                <label htmlFor="newPassword">New Password<span className="text-danger">*</span></label>
                {error.newPassword && <span className="text-danger">{error.newPassword}</span>}
                <input
                  type="password"
                  className="form-control"
                  id="newPassword"
                  name="newPassword"
                  value={newPassword}
                  onChange={this.handlePasswordChange}
                  autoComplete="off"
                />
              </div>
 
              <div className="form-group text-dark">
                <label htmlFor="confirmPassword">Confirm Password<span className="text-danger">*</span></label>
                {error.confirmPassword && <span className="text-danger">{error.confirmPassword}</span>}
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={this.handlePasswordChange}
                  autoComplete="off"
                />
              </div>
 
              <div className="reset-button form-group mt-2 rounded text-center">
                <button className="btn btn-normal submitbtn" type="submit" disabled={isLoading}>
                  Reset Password
                  {loadingButton === 'reset' && isLoading && (
                    <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                  )}
                </button>
 
                {isPasswordReset && (
                  <div className="alert alert-success m-2" role="alert">
                    {responseMessage}
                  </div>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}
 
export default withRouter(ForgotPassword) ;
 
 