import brandlogo from "./brandlogo.png"
import introimage from "./introimage.jpg"
import filtersectionbackground from "./filtersectionbackground.png"
import filtersectionimage from "./filtersectionimage.png"
import bestplaceimage from "./bestplace.png"
import findbestplacepara from "./Find Best Place.png"
import featuresectionbackground from "./bg3.png"
import image404 from './404.jpg'
import house1 from './house1.jpg'
import house2 from './house2.jpg'
import house3 from './house3.jpg'
import house4 from './house4.jpg'
import house5 from './house5.jpg'
import house6 from './house6.jpg'
import house7 from './house7.jpg'
import house8 from './house8.jpg'
import house9 from './house9.jpg'
import house10 from './house10.jpg'
import security from './security.png'
import datanotfound from './Data-not-Found.png'





export const imgAssests = {
    brandlogo,
    introimage,
    filtersectionbackground,
    filtersectionimage,
    bestplaceimage,
    findbestplacepara,
    featuresectionbackground,
    image404,
    house1,
    house2,
    house3,
    house4,
    house5,
    house6,
    house7,
    house8,
    house9,
    house10,
    security,
    datanotfound
}