import React, { useState,useEffect } from "react";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import LocationSearchInput from "./LocationSearchInput"
import "./LocationWithGooglemap.css"
import {
    setKey,
    setDefaults,
    setLanguage,
    setRegion,
    fromAddress,
    fromLatLng,
    fromPlaceId,
    setLocationType,
    geocode,
    RequestType,
  } from "react-geocode";

  setDefaults({
    key: "AIzaSyBaQS-DVHJ_xoqzXvTWZ8BqqpSzGtSJXQQ", // Your API key here.
    language: "en", // Default language for responses.
    region: "es", // Default region for responses.
  });
// street view
  // https://github.com/google-map-react/google-map-react/issues/251

const LocationWithGooglemap = ({CurrentLocation,onClickgetAddressfromMap}) => {
    const [location,setLocation]= useState(CurrentLocation);
    const [newlocation,setNewLocation]=useState('')
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyBaQS-DVHJ_xoqzXvTWZ8BqqpSzGtSJXQQ'
    });
    useEffect(() => { setLocation(CurrentLocation) }, [CurrentLocation]);
    const onLoadMarker = (marker) => {
    };
    const getLocation = (latLng) => {
        setLocation(latLng)
    }
   const handleClickedMap = (event) => {
    var lat = event.latLng.lat(), lng = event.latLng.lng()
        fromLatLng(lat, lng).then(
            response => {
              const address = response.results[0].formatted_address;
              setNewLocation(address)
              onClickgetAddressfromMap(address)
            },
            error => {
              console.error(error);
            }
          );        
     }
    return (       
        <div className="row">        
            <div className="col">
                <div className="map text-center pt-3">
                <i className="pt-3 pb-3 fa fa-map-marker-alt"></i> <span className="pt-3 pb-3 taplocation">Tap/click on the map to change the pin location</span>
                        {!isLoaded ? (
                            <h3>Loading…..</h3>
                        ) : (
                            <>
                                <GoogleMap
                                    mapContainerClassName="map_container"
                                    center={location}
                                    zoom={10}
                                    onClick={handleClickedMap } 
                                    options={{
                                        gestureHandling: "greedy",
                                      }}
                                >
                                    <MarkerF position={location} onLoad={onLoadMarker} />
                                </GoogleMap>
                            </>
                        )
                        }
                </div>
            </div>
        </div>
    );
};
export default LocationWithGooglemap;
