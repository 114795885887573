import React from "react";
import Moment from 'moment';
import "../PostAds/PostAds.scss";
import { PanelList } from "../../constant/PostAdsPanelList";
 
class DisplayDataEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickContinue(panel)
  {
    this.props.callbackNavigatePanel(panel)


  }

  
 
  render() {
    const { DataforDisplay, HousingTypeList, AmenitiesList, RoomkindList, RoomDevelopmentList,
      SharedHousingRuleList, AdsPosterRoleList } = this.props;
    const {
      postMyAdAddress = {},
      postMyAdHousing = {},
      postMyAdAboutRoom = {},
      postMyAdIdealRoomMate = {},
      postMyAdProfile = {},
      postMyAdFiles = {}
    } = DataforDisplay;
    const imageFiles = (postMyAdFiles.fileDetailsList || []).filter(file => file.isImageFile === true);
    const videoFiles = (postMyAdFiles.fileDetailsList || []).filter(file => file.isImageFile === false);
 
    const Houseinfo = HousingTypeList.find((e) => e.key == postMyAdHousing.housingTypeId)
    const Amenities = AmenitiesList.filter(element => postMyAdHousing.amenityIdList?.includes(element.key));
    const Roomkind = RoomkindList.find((e) => e.key == postMyAdAboutRoom.roomKindId)
    const RoomDevelopment = RoomDevelopmentList.find((e) => e.key == postMyAdAboutRoom.roomDevelopmentId)
    const SharedHousingRules = SharedHousingRuleList.filter(element => postMyAdIdealRoomMate.saRuleList?.includes(element.key));
    const AdsPostBy = AdsPosterRoleList.find((e) => e.key == postMyAdProfile.postMyAdRoleId);
   
    console.log(postMyAdFiles)
 
    return (
      <div className="display-container topdisplay btn-out-border border border-2">
        {postMyAdAddress.addressName && <div className="row pt-2">
          <div className="col-md-3 ">
            <span className="label-1">Address</span>
            <i className="p-3 fa-regular fa-pen-to-square"
            onClick={() => this.onClickContinue(PanelList.ADDRESS)}></i>
          </div>
          <div className="p-2 col-md-9">
            <span className="value"> {postMyAdAddress.addressName}</span>
          </div>
        </div>
        }
        {
          postMyAdHousing.housingId > 0 && <div className="row">
            <div className="col-md-3 pt-3">
              <span className="label-1">Housing</span>
              <i className="p-3 fa-regular fa-pen-to-square" 
              onClick={() => this.onClickContinue(PanelList.HOUSING)}></i>
            </div>
            <div className="col-md-9 p-2">
              <div class="row">
                <div className="col-md pt-3">
                  <span className="label-2"> Housing Type</span> <span className="text-danger"> *</span> <p className="label-value"> {Houseinfo?.value}</p>
                </div>
                <div className="col-md pt-3">
                  <span className="label-2">Total Room(s)</span><p className="label-value">{postMyAdHousing.totalRoomCount}</p>
                </div>
                <div className="col-md pt-3">
                  <span className="label-2">No Bathroom(s)</span><p className="label-value">{postMyAdHousing.totalBathroomCount}</p>
                </div>
                <div className="col-md pt-3">
                  <span className="label-2">No Rooms for Rent</span> <p className="label-value">{postMyAdHousing.totalRoomRentCount}</p>
                </div>
              </div>
            </div>
          </div>
        }
                {
          postMyAdHousing.housingId > 0 && <div className="row">
            <div className="row">
                <div className="col-md-3">
               
                </div>
                <div className="col-md-9 pt-3">
                      <span className="label-2"> Amenities </span>
                </div>
            </div>
            <div className="row">
              <div className="col-md-3">
 
              </div>
             
              <div className="col-md-9 pt-2">
              <div className="d-flex flex-wrap">
              {Amenities.map((a) => <div className="m-1"><span className="btn btn-sm btn-outline-dark background-white">{a.value}</span></div>)}
              </div>  
             
            </div>
            </div>
          </div>
        }
        {
          postMyAdAboutRoom.postMyAdId > 0 && <div className="row">
            <div className="col-md-3 pt-3">
              <span className="label-1">About the room</span>
              <i className="p-3 fa-regular fa-pen-to-square"
              onClick={() => this.onClickContinue(PanelList.ABOUT_THE_ROOM)}></i>
            </div>
            <div className="col-md-9 p-2">
              <div class="row">
                <div className="col-md pt-3">
                <span className="label-2">Kind</span> <span className="text-danger"> *</span> <p className="label-value">{Roomkind?.value}</p>
                </div>
                <div className="col-md pt-3">
                <span className="label-2">Development</span> <span className="text-danger"> *</span> <p className="label-value">{RoomDevelopment?.value}</p>
                </div>
                <div className="col-md pt-3">
                <span className="label-2">Monthly Rent</span> <span className="text-danger"> *</span> <p className="label-value">{postMyAdAboutRoom.monthlyRent}</p>
 
                </div>
                <div className="col-md pt-3">
                <span className="label-2">Room Area</span><p className="label-value">{postMyAdAboutRoom.roomArea}</p>
                 
                </div>
                <div className="row">
                <div className="col-6">
                <p className="pt-2">{postMyAdAboutRoom.isChargesNotIncluded ? <i class="fa-solid fa-check icon-red"></i> : <i class="fa-solid fa-xmark icon-red"></i>}Charges Included</p>
                </div>
                <div className="col-6">
                <p className="pt-2">{postMyAdAboutRoom.hasPrivateBathroom ? <i class="fa-solid fa-check icon-red"></i> : <i class="fa-solid fa-xmark icon-red"></i>}Private Bath Room</p>
                </div>
                </div>
                <div className="col-md pt-3">
                <span className="label-2">Availability Date</span><p className="label-value">{Moment(postMyAdAboutRoom.availabilityDate).format('DD-MMM-YYYY')}</p>
                </div>
                <div className="col-md pt-3">
                <span className="label-2">Minimum Duration (months)</span><p className="label-value">{postMyAdAboutRoom.minimumDuration}</p>
                </div>
              </div>
            </div>
          </div>
        }
        {
          postMyAdIdealRoomMate.idealRoomMateId > 0 && <div className="row">
            <div className="col-md-3 pt-3">
              <h3 className="label-1 mb-0">Current</h3>
              <span className="label-1 mt-0">Roommates</span>
            <span> <i className="p-2 fa-regular fa-pen-to-square"
              onClick={() => this.onClickContinue(PanelList.CURRENT_ROOMMATES)}></i> </span>
            </div>
            <div className="col-md-9 pt-3">
            <span className="label-2 pt-3">Number of roommates living in the accommodation</span>
              <p className="label-value">{postMyAdIdealRoomMate.currentRoommatesCount}</p>
            </div>
          </div>
        }
        {
          postMyAdIdealRoomMate.idealRoomMateId > 0 && <div className="row">
            <div className="col-md-3 pt-3">
              <span className="label-1">Your ideal roommate</span>
            </div>
            <div className="col-md-9">
              <div className="row pt-3">
                <div className="col-md">
                <span className="label-2">Sex</span> <span className="text-danger"> *</span> <p className="label-value"> {postMyAdIdealRoomMate.sex}</p>
                </div>
                <div className="col-md">
                <span className="label-2">Professional situation</span><p className="label-value">{postMyAdIdealRoomMate.professionalSituation}</p>
                </div>
                <div className="col-md">
                <span className="label-2">Age From</span><p className="label-value">{postMyAdIdealRoomMate.ageFrom}</p>
                </div>
                <div className="col-md">
                <span className="label-2">Age up to</span><p className="label-value">{postMyAdIdealRoomMate.ageTo}</p>
                </div>
              </div>
            </div>
          </div>
        }
        {
          postMyAdIdealRoomMate.idealRoomMateId > 0 && <div className="row">
            <div className="col-md-3 pt-3">
              <span className="label-1">The rules of your shared accommodation</span>
            </div>
            <div className="col-md-9 pt-3">
              <div class="d-flex flex-row bd-highlight mb-3">
                <div>
                  <p>{SharedHousingRules.map((a) => <span className="p-2"><i class="fa-solid fa-check icon-red"></i>{a.value}</span>)}</p>
                </div>
              </div>
            </div>
 
          </div>
        }
        {
          postMyAdProfile.postMyAdProfileId > 0 && <div className="row">
            <div className="col-md-3 pt-3">
              <span className="label-1">Your profile</span>
              <i className="p-3 fa-regular fa-pen-to-square"
              onClick={() => this.onClickContinue(PanelList.YOUR_PROFILE)}></i>
            </div>
            <div className="col-md-9 pt-3">
              <div class="row">
                <div className="col-md">
                <span className="label-2">About you</span> <span className="text-danger"> *</span> <p className="label-value">{AdsPostBy?.value}</p>
                </div>
                <div className="col-md">
                <span className="label-2">Phone</span> <span className="text-danger"> *</span> <p className="label-value">{postMyAdProfile.phoneNumber}</p>
                </div>
                <div className="col-md">
                <span className="label-2">First Name</span> <span className="text-danger"> *</span> <p className="label-value">{postMyAdProfile.firstName}</p>
                </div>
                <div className="col-md">
                <span className="label-2">Last Name</span> <span className="text-danger"> *</span> <p className="label-value">{postMyAdProfile.lastName}</p>
                </div>
              </div>
            </div>
          </div>
        }
        {
          postMyAdProfile.postMyAdProfileId > 0 && <div className="row">
            <div className="col-md-3">
              <span className="label-1">Description</span>
            </div>
            <div className="col-md-9">
              <div class="row">
                <div className="col-md">
                <span className="label-2">Ad Title</span><p className="label-value">{postMyAdProfile.adTitle}</p>
                </div>
              </div>
            </div>
          </div>
        }
        {
          postMyAdProfile.postMyAdProfileId > 0 && <div className="row">
            <div className="col-md-3">
            </div>
            <div className="col-md-9">
              <div class="row">
                <div className="col-md">
                <span className="label-2">Description of your accommodation</span> <p className="label-value">{postMyAdProfile.description}</p>
                </div>
              </div>
            </div>
          </div>
        }
        {
  postMyAdProfile.postMyAdProfileId > 0 && (
    <div className="row pt-3">
      <div className="col-md-3">
        <span className="label-1">Your images</span>
        <i 
          className="p-3 fa-regular fa-pen-to-square" 
          onClick={() => this.onClickContinue("YourImage")}
        ></i>
      </div>
      <div className="col-md-9">
        <div className="row">
          {imageFiles.length > 0 ? (
            imageFiles.map((file) => (
              <div key={file.fileId} className="col-4 mb-3 d-flex flex-wrap gap-3">
                <img
                  className="w-75 rounded"
                  src={file.filePath}
                  alt={file.fileName}
                />
              </div>
            ))
          ) : (
            <div className="col-12">
              <span>No images available</span>
            </div>
          )}
          
        </div>
      </div>
    </div>
  )
}
{
  postMyAdProfile.postMyAdProfileId > 0 && (
    <div className="row pt-3">
      <div className="col-md-3">
        <span className="label-1">Your Video</span>
        <i 
          className="p-3 fa-regular fa-pen-to-square" 
          onClick={() => this.onClickContinue("YourVideo")}
        ></i>
      </div>
      <div className="col-md-9">
        <div className="row">
          {videoFiles.length > 0 ? (
            videoFiles.map((file) => (
              <div key={file.fileId} className="col-4 mb-3 d-flex flex-wrap gap-3">
                <video width="320" height="240" controls>
                    <source src={file.filePath} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
              </div>
            ))
          ) : (
            <div className="col-12">
              <span>No Video available</span>
            </div>
          )}
          
        </div>
      </div>
    </div>
  )
}
      </div>
    );
  }
}
export default DisplayDataEdit;
 
 
 