import React from "react";
import "./CountryList.css";
import { withRouter } from "../../hooks/useRouteHooks"
import { GetCityList, GetStateList, MasterAPI } from "../../api/apiLoader";

const PageSize = 4.0;

//Option Selector JSON
const optionsForStateCity = [
    {
        option:"0",
        value:"Enter the Options"
    },
    {
        option: "1",
        value: "State"
    },
    {
        option: "2",
        value: "City"
    },
];

class CountryWithCity extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            //Country List API response
            CountryLists:[],
            CityList:[],
            StateList:[],
            selectedoptions:"",
            selectedCityListlength:0,
            //Values Declaration
            selectedCityList: [], // Ensure this is initialized to an empty array
            selectedStateList:[],
            selectedCountryName: "", // Will store the selected country's name
            CurrentCountryPageNo: 1,
            TotalCountryPage: 0,
            CurrentCityPageNo: 1,
            CurrentStatePageNo:1,
            TotalCityPage: 0 , 
            TotalStatePage:0,  
        };
    }

    componentDidMount() {
        //Master API call for Country List
        MasterAPI()
          .then((response) => {
            const result = response.data;
            const countryList = result.countryList;
            this.setState({
                CountryLists: countryList,
                TotalCountryPage: Math.ceil(countryList.length / this.state.PageSize),
            });
          })
          .catch((error) => {
            console.log("There was an error occured during the process", error);
          })
    }

    //Handle the options 
    handleoptionschange = (event) => {
        this.setState({ selectedoptions: event.target.value }, () => {
            console.log('Selected Option:', this.state.selectedoptions);
        });
    }

    //Countries Function

    //Handle The Previous Country Page Click
    handleCountryPreviousPageClick = () => {
        const { CurrentCountryPageNo, TotalCountryPage } = this.state;
        let newPage = CurrentCountryPageNo - 1;
        if (newPage < 1) {
            newPage = TotalCountryPage;
        }
        this.setState({ CurrentCountryPageNo: newPage })
    };
    
    //Handle The next Country Page Click
    handleCountryNextPageClick = () => {
        const { CurrentCountryPageNo, TotalCountryPage } = this.state;
        let newPage = CurrentCountryPageNo + 1;
        if (newPage > TotalCountryPage) {
            newPage = 1;
        }
        this.setState({ CurrentCountryPageNo: newPage })
    };

    //Country Click 
    onCountryClick = (countryName) => {
        const {selectedoptions} = this.state;
        // debug to check
        // console.log("Selected Country:", countryName);
        // console.log("Selected option :" ,selectedoptions);

        if(selectedoptions == "Enter the Options"){
            // alert("Enter the Correct Option");
        }
        else if (selectedoptions == "City")
        {
            // Fetch city list based on the selected country
            GetCityList(countryName)
                .then((response) => {
                    const result = response.data;
                    const selectedCityListlength = result.length;
                    console.log("City List API Response:", result);
                    this.setState({
                        selectedCityList: result,
                        selectedCityListlength,
                        selectedCountryName: countryName,
                        CurrentCityPageNo: 1,
                        TotalCityPage: Math.ceil(result.length / PageSize),
                    });
                })
                .catch((error) => {
                    console.error("Error while fetching city data", error);
                });
        }
        else 
        {
            // alert("Good Things Take Time ");
            GetStateList(countryName)
                .then((response) => {
                    const result = response.data;
                    const selectedStateListlength = result.length;
                    console.log("State List API Response" , result)
                    this.setState({
                        selectedStateList:result,
                        selectedStateListlength,
                        selectedCountryName:countryName,
                        CurrentStatePageNo:1,
                        TotalStatePage:Math.ceil(result.length / PageSize),
                    })
                })
                .catch((error) => {
                    console.error("Error while fetching the State data" ,error);
                })
        }
      };
      

    // Render the Country List
    renderCountryList = () => {
        const { CountryLists, CurrentCountryPageNo, selectedCountryName } = this.state;
        const startIndex = (CurrentCountryPageNo - 1) * PageSize;
        const endIndex = startIndex + PageSize;
      
        // Slice the country list for the current page
        const countriesToShow = CountryLists.slice(startIndex, endIndex);
      
        return countriesToShow.map((country, index) => {
          const correctedImage = country.includes(" ") ? country.replace(" ", "-") : country;
          console.log(correctedImage);

          return (
            <div key={index} className="col d-flex justify-content-center">
              <div
                className={`country-image ${selectedCountryName === country ? "country-image-selected" : ""}`}
                onClick={() => this.onCountryClick(country)}
              >
                <img src={`/imgAssests/${correctedImage}.png`} alt={country} />
                <p>{country}</p>
              </div>
            </div>
          );
        });
      };
      
    //Cities Section

    handleCityPreviousPageClick = () => {
        const { CurrentCityPageNo, TotalCityPage } = this.state;
        let newPage = CurrentCityPageNo - 1;
        if (newPage < 1) {
            newPage = TotalCityPage;
        }
        this.setState({ CurrentCityPageNo: newPage })
    };

    handleCityNextPageClick = () => {
        const { CurrentCityPageNo, TotalCityPage } = this.state;
        let newPage = CurrentCityPageNo + 1;
        if (newPage > TotalCityPage) {
            newPage = 1;
        }
        this.setState({ CurrentCityPageNo: newPage })
    };

    //Routing
    onCityClick = (cityName, country,selectedoptions) => {
        console.log("City:", cityName, "Country:", country);
        const formattedCityName = cityName.replace(/ /g, "%20");
        const formattedCountry = country.replace(/ /g, "%20");  
        const { navigate } = this.props.router;
        navigate(`/hotel-list/${selectedoptions}/${formattedCountry}/${formattedCityName}`);
    };


    onStateClick = (statename,country,selectedoptions) => {
        console.log(country);
        const formattedStateName = statename.replace(/ /g, "%20");
        const formattedCountry = country.replace(/ /g, "%20"); 
        const {navigate} = this.props.router;
        navigate(`/hotel-list/${selectedoptions}/${formattedCountry}/${formattedStateName}`);
    }
    

    //State Section

    //Handle the Previous Click
    handleStatePreviousPageClick = () => {
        const { CurrentStatePageNo, TotalStatePage } = this.state;
        let newPage = CurrentStatePageNo - 1;
        if (newPage < 1) {
            newPage = TotalStatePage;
        }
        this.setState({ CurrentStatePageNo: newPage })
    };

    //handle the Next Click
    handleStateNextPageClick = () => {
        const { CurrentStatePageNo, TotalStatePage } = this.state;
        let newPage = CurrentStatePageNo + 1;
        if (newPage > TotalStatePage) {
            newPage = 1;
        }
        this.setState({ CurrentStatePageNo: newPage })
    };

    //Rendering Section

    //Render the City List

    renderCityList = () => {
        const { selectedCityList, CurrentCityPageNo ,selectedCountryName,selectedoptions} = this.state;
        const startIndex = (CurrentCityPageNo - 1) * PageSize;
        const endIndex = startIndex + PageSize;
        const citiesToShow = selectedCityList.slice(startIndex, endIndex);

        return citiesToShow.map((city, index) => (
            <div key={index} className="col d-flex justify-content-center">
                <div className="city-image" onClick={() => this.onCityClick(city,selectedCountryName,selectedoptions)}>
                    <img src="/imgAssests/City.png" alt="" />
                    <p>{city}</p>
                </div>
            </div>
        ));
    };

    //Render the State List

    renderStateList = () => {
        const { selectedStateList, CurrentStatePageNo,selectedCountryName,selectedoptions } = this.state;
        const startIndex = (CurrentStatePageNo - 1) * PageSize;
        const endIndex = startIndex + PageSize;
        const statesToShow = selectedStateList.slice(startIndex, endIndex);

        return statesToShow.map((state, index) => (
          <div key={index} className="col d-flex justify-content-center">
            <div className="city-image" onClick={() => this.onStateClick(state,selectedCountryName,selectedoptions)}>
              <img src="/imgAssests/State.png" alt="" />
              <p>{state}</p> {/* Display the state name */}
            </div>
          </div>
        ));
      };
      

    render() {
        const 
        { CurrentCountryPageNo, TotalCountryPage ,selectedCityListlength ,selectedStateListLength,
            selectedCountryName ,CurrentCityPageNo ,TotalCityPage ,selectedoptions,CurrentStatePageNo,TotalStatePage
        } = this.state;
        return(
            <div className="col country-container">
                {/* Heading Section */}
                <div className="row">
                    <div className="col">
                        <h2 className="text-center headcountry">POPULAR COUNTRIES</h2>
                        <hr className="title-underline" />
                        <div className="d-flex justify-content-center align-items-center">
                            <h3 className="list me-2">List of cities by international visitors</h3>
                            <select className="small-dropdown" name="options" onChange={this.handleoptionschange}>
                                {
                                    optionsForStateCity.map((optionsfor) => (
                                        <option className="small-dropdownoption" key={optionsfor.key} value={optionsfor.value}>{optionsfor.value}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                {/* Countries Section */}
                <div className="row align-items-center">
                    <div className="col-2">
                        <div className="navigate-icon float-end">
                            <button type="button" className="btn" disabled={CurrentCountryPageNo === 1} onClick={this.handleCountryPreviousPageClick}>
                                <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="row align-items-center justify-content-center">
                            {
                                this.renderCountryList()
                            }
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="navigate-icon">
                            <button type="button" className="btn float-start" disabled={CurrentCountryPageNo === TotalCountryPage} onClick={this.handleCountryNextPageClick}><i className="fa fa-long-arrow-right" aria-hidden="true"></i></button>
                        </div>
                    </div>
                </div>
                {
                    selectedoptions =="City"  && <div className="row">
                        <div className="col-12 mt-3">
                            <h4 className="text-center popular">Popular Cities by {selectedCountryName}</h4>
                        </div>
                    </div>    
                } 
                {
                    selectedoptions == "State" && <div className="row">
                        <div className="col-12 mt-3">
                            <h4 className="text-center popular">Popular States by {selectedCountryName}</h4>
                        </div>
                    </div>    
                } 
                {selectedoptions == "City" &&
                    <div className="row align-items-center">
                        <div className="col-2">
                            {

                                selectedCountryName && <div className="navigate-icon float-end">
                                    <button type="button" className="btn" disabled={CurrentCityPageNo === 1} onClick={this.handleCityPreviousPageClick}>
                                        <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                                    </button>
                                </div>
                            }
                        </div>
                        <div className="col-8">
                            <div className="row align-items-center justify-content-center">
                                {
                                    this.renderCityList()
                                }
                            </div>
                        </div>
                        <div className="col-2">
                            {
                                selectedCountryName && <div className="navigate-icon float-start">
                                    <button type="button" className="btn float-start" disabled={CurrentCityPageNo === TotalCityPage} onClick={this.handleCityNextPageClick}>
                                        <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                }
                {selectedoptions == "State" &&
                    <div className="row align-items-center">
                        <div className="col-2">
                            {

                                selectedCountryName && <div className="navigate-icon float-end">
                                    <button type="button" className="btn" disabled={CurrentStatePageNo === 1} onClick={this.handleStatePreviousPageClick}>
                                        <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                                    </button>
                                </div>
                            }
                        </div>
                        <div className="col-8">
                            <div className="row align-items-center justify-content-center">
                                {
                                    this.renderStateList()
                                }
                            </div>
                        </div>
                        <div className="col-2">
                            {
                                selectedCountryName && <div className="navigate-icon float-start">
                                    <button type="button" className="btn float-start" disabled={CurrentStatePageNo === TotalStatePage} onClick={this.handleStateNextPageClick}>
                                        <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                    </button>
                                </div>
                            }
                        </div>
                    </div> 
                }
            </div>   
        )
    }
}

export default withRouter(CountryWithCity);
