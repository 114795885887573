import React from "react";
import { SignUpUser } from "../../api/apiLoader"
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {setLoginStatus, setLogoutStatus} from "../../state/actions/login.status"
import './signup.css';

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        firstName: '',
        lastName: '',
        emailId: '',
        password: '',
        mobile: '',
        gender: '',
        marriageStatus: '',
        occupation: '',
        dob: '',
      },
      error: {
        firstName: '',
        lastName: '',
        emailId: '',
        password: '',
        mobile: '',
        gender: '',
        marriageStatus: '',
        occupation: '',
        dob: '',
      },
      isLoading: false,
      networkError: '',
      isSuccess: false,
      responseMessage: ''
    };
  }
  
  componentDidMount() {
    // this.setBackgroundImage();
  }
  setBackgroundImage = () => {
    const mainContainer = document.getElementById("mainContainer");
    // mainContainer.className = "background-home-page";
  };
  handleChange = (e) => {
    const { formData } = this.state;
    const UpdatedData = {
      ...formData,
      [e.target.name]: e.target.value,
    };
    this.setState({ formData: UpdatedData });
  };

  updateSignUpStatus = ()=> {
    console.log('updateSignUpStatus')
      this.props.dispatch(setLoginStatus())
  }
  updateSignOutStatus = ()=> {
    console.log('updateSignUpStatus')
      this.props.dispatch(setLogoutStatus())
  }

  validation = () => {
    let isValid = true;
    let { error, formData } = this.state;
    const { firstName, lastName, emailId, password, mobile, gender, marriageStatus, occupation, dob } = formData;
    if (firstName.trim() === "") {
      error.firstName = "Please enter the first name";
    }
    else {
      error.firstName = "";
    }
    if (lastName.trim() === "") {
      error.lastName = "Please enter the last name";
    }
    else {
      error.lastName = "";
    }
    if (emailId.trim() === "") {
      error.emailId = "Please enter emailId";
    }
    else {
      error.emailId = "";
    }
    if (password.trim() === "") {
      error.password = "Please enter password";
    }
    else {
      error.password = "";
    }
    if (mobile.trim() === "") {
      error.mobile = "Please enter mobile";
    }
    // else if (password.trim() != "" && confirmPassword.trim() != "" && confirmPassword.trim() != password.trim()) {
    //   error.confirmPassword = "password and confirmPassword not match";
    // }
    else {
      error.mobile = "";
    }

    // else {
    //   error.confirmPassword = "";
    // }
    if (dob.trim() === "") {
      error.dob = "Please enter date of birth";
    }
    else {
      error.dob = "";
    }

    if (gender.trim() === "") {
      error.gender = "Please select gender";
    }
    else {
      error.gender = "";
    }
    if (marriageStatus.trim() === "") {
      error.marriageStatus = "Please select marriageStatus";
    }
    else {
      error.marriageStatus = "";
    }
    if (occupation.trim() === "") {
      error.occupation = "Please enter occupation";
    }
    else {
      error.occupation = "";
    }
    Object.entries(error).map(entry => {
      let value = entry[1];
      isValid = isValid && value === ""
    });
    this.setState({ ...error })

    if (isValid) {
      const { firstName, lastName, emailId, password, mobile, gender, marriageStatus, occupation,dob } = formData;
      const data = {
        firstName, lastName, emailId, password, gender, marriageStatus, occupation, mobile, dob
      }
      this.setState({ isLoading: true })
      try {
        SignUpUser(data).then(response => {
          const { isSuccess = false, message = '' } = response?.data || {};
          this.setState({ isLoading: false, networkError: '', isSuccess, responseMessage: message })
        }).catch(err => {
          console.log(err)
          this.setState({ isLoading: false, networkError: 'Network error' })
        })
      } catch (error) {
        console.log(error)
      }

    }
    else {

    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const isvalidform = this.validation()
  };
  render() {
    const { formData, error, isLoading, networkError, isSuccess, responseMessage } = this.state;
    return (
      <div className="row justify-content-center">
  <div className="col-md-6 mt-5 mb-3 pt-2 rounded sign-up-container text-light">
      <form noValidate autoComplete="off" className="text-left" onSubmit={this.handleSubmit}>
        <h1 className="signin-title mb-3 text-center text-light">Signup</h1>

        <div className="row"> {/* Row for first name and last name */}
          <div className="form-group col-md-6">
            <label htmlFor="firstName">First Name<span className="text-danger">*</span></label>
            {error.firstName && <span className="text-danger">{error.firstName}</span>}
            <input
              type="text"
              className="form-control"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={this.handleChange}
              autoComplete="off"
            />
          </div>

          <div className="form-group col-md-6">
            <label htmlFor="lastName">Last Name<span className="text-danger">*</span></label>
            {error.lastName && <span className="text-danger">{error.lastName}</span>}
            <input
              type="text"
              className="form-control"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={this.handleChange}
              autoComplete="off"
            />
          </div>
        </div>

        <div className="row"> {/* Row for email and mobile */}
          <div className="form-group col-md-6 ">
            <label htmlFor="emailId">Email Address<span className="text-danger">*</span></label>
            {error.emailId && <span className="text-danger">{error.emailId}</span>}
            <input
              type="email"
              className="form-control"
              id="emailId"
              name="emailId"
              value={formData.emailId}
              onChange={this.handleChange}
              autoComplete="off"
            />
          </div>

          <div className="form-group col-md-6">
            <label htmlFor="mobile">Mobile No<span className="text-danger">*</span></label>
            {error.mobile && <span className="text-danger">{error.mobile}</span>}
            <input
              type="tel"
              className="form-control"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={this.handleChange}
              autoComplete="off"
            />
          </div>
        </div>

        <div className="row"> {/* Row for password and date of birth */}
          <div className="form-group col-md-6">
            <label htmlFor="password">Password<span className="text-danger">*</span></label>
            {error.password && <span className="text-danger">{error.password}</span>}
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              value={formData.password}
              onChange={this.handleChange}
              autoComplete="off"
            />
          </div>

          <div className="form-group col-md-6">
            <label htmlFor="dob">Date of Birth<span className="text-danger">*</span></label>
            {error.dob && <span className="text-danger">{error.dob}</span>}
            <input
              type="date"
              className="form-control"
              id="dob"
              name="dob"
              value={formData.dob}
              onChange={this.handleChange}
              required
            />
          </div>
        </div>

        <div className="row"> {/* Row for occupation */} 
          <div className="form-group col-md-6">
            <label htmlFor="occupation">Occupation<span className="text-danger">*</span></label>
            {error.occupation && <span className="text-danger">{error.occupation}</span>}
            <input
              type="text"
              className="form-control"
              id="occupation"
              name="occupation"
              value={formData.occupation}
              onChange={this.handleChange}
              autoComplete="off"
            />
          </div>
        </div>

        {/* Marriage Status and Gender in the same row */}
        <div className="row mt-2 ">
          <div className="form-group col-md-6 mt-1">
            <label>Marriage Status<span className="text-danger">*</span></label>
            {error.marriageStatus && <span className="text-danger">{error.marriageStatus}</span>}
            <div className="d-flex">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="marriageStatus"
                  id="single"
                  value="Single"
                  onChange={this.handleChange}
                />
                <label className="form-check-label text-dark" htmlFor="single">Single</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="marriageStatus"
                  id="married"
                  value="Married"
                  onChange={this.handleChange}
                />
                <label className="form-check-label text-dark" htmlFor="married">Married</label>
              </div>
            </div>
          </div>

          <div className="form-group col-md-6 mt-1">
            <label>Gender<span className="text-danger">*</span></label>
            {error.gender && <span className="text-danger">{error.gender}</span>}
            <div className="text-dark d-flex">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="male"
                  value="Male"
                  onChange={this.handleChange}
                />
                <label className="form-check-label" htmlFor="male">Male</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="female"
                  value="Female"
                  onChange={this.handleChange}
                />
                <label className="form-check-label" htmlFor="female">Female</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="other"
                  value="Other"
                  onChange={this.handleChange}
                />
                <label className="form-check-label" htmlFor="other">Other</label>
              </div>
            </div>
          </div>
        </div>

        <div className="signupbutton form-group mt-2 rounded text-center">
          <button className="btn btn-normal text submit-button" type="submit">Submit</button>
          {isLoading && <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>}
          {networkError && <div className="alert alert-danger m-2" role="alert">{networkError}</div>}
          {isSuccess === true && responseMessage && <div className="alert alert-success m-2" role="alert">{responseMessage}</div>}
          {isSuccess === false && responseMessage && <div className="alert alert-danger m-2" role="alert">{responseMessage}</div>}
        </div>
      </form>
  </div>
</div>

    );
  }
}
export default connect()(Signup)