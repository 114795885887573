import UserLoginStatus from '../actionTypes/login.actiontype'

const initialState = {
    isUserLoggedin: false,
    LoggedInuserData: {
        userId: 0,
        firstName: '',
        lastName: '',
        emailId: '',
        mobile: '',
    }
}

function setLoginStatus(state, action) {
    return {
        ...state,
        isUserLoggedin: action.data
    }
}


function setLoggedUserData(state, action) {
    return {
        ...state,
        LoggedInuserData: action.data
    }
}

const loginstate = (state = initialState, action) => {
    switch (action.type) {
        case UserLoginStatus.SET_LOGIN_STATUS:
            return setLoginStatus(state, action)
        case UserLoginStatus.SET_LOGOUT_STATUS:
            return setLoginStatus(state, action)
        case UserLoginStatus.SET_LOGGED_USER_DATA:
            return setLoggedUserData(state, action)
        default:
            return state;

    }
}

export default loginstate;