import React from "react";
import { useNavigate } from "react-router-dom";
import "./getluxary.css";

// Main Function Component
const Getluxary = () => {
    const navigate = useNavigate();

    const onclicklookingRoom = () => {
        navigate('/hotel-list');
    };

    const onclickpostRoom =() => {
        navigate(`post-your-ads`);

    }

    return (
        <div className="col p-0 luxarySectionBackgroundwrapper">
            <div className="luxary-section-background-inner p-5">
                <div className="row">
                    <div className="col">
                        <div className="Luxarycontent text-center">
                            <h2 className="mb-4">GET A LUXURY HOME IT'S EASY</h2>
                            <hr className="title-underline" />
                            <p className="mb-4">Become a Member to contact the latest ads or post a room. You are almost there.</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center Luxarycontent-btn">
                    <div className="col-auto">
                        <button className="p-2 g-col-6 btn btn-danger" onClick={onclicklookingRoom}>I'm Looking for a Room</button>
                    </div>
                    <div className="col-auto">
                        <button className="p-2 g-col-6 btn btn-light" onClick={onclickpostRoom}>I have a Room</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Getluxary;
