import { divide } from "lodash";
import React from "react";
import '../PostAds/PostAds.scss';
import { GetMasterData, GetPostMyAdById, PostHouseAddress, PostHouseAddressUpdate } from "../../api/apiLoader";
import { PanelList } from "../../constant/PostAdsPanelList";
import { withRouter } from "../../hooks/useRouteHooks";
import { connect } from "react-redux";
import { loggedinUserIdSelector } from "../../state/selectors/login.selector";
import Housing from "../PostAds/Housing";
import DisplayData from "../PostAds/DisplayData";
import LocationSearchInput from "../PostAds/LocationSearchInput";
import LocationWithGooglemap from "../PostAds/LocationWithGooglemap";
import HousingEdit from "./housingEdit";
import AboutTheRoomEdit from "./AboutTheRoomEdit";
import CurrentRoommatesEdit from "./CurrentRoommatesEdit";
import YourProfileEdit from "./YourProfileEdit";
import DisplayDataEdit from "./DisplayDataEdit";
import YourImageEdit from "./YourImageEdit";
import YourVideoEdit from "./YourVideoEdit";

class postadsupdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            PostAdsId: 0,
            isLoading: false,
            CurrentLocation: {},
            customerInfo: {
                AddressInfo: "",
            },
            displayStatus: {
                Address: false,
                Housing: false,
                AbouttheRoom: false,
                CurrentRoommates: false,
                Yourprofile: false,
                YourImage:false,
                YourVideo:false,
                Youraccount: false,
                AdsList: true
            },
            housingTypecontents: [],
            roomDevelopmentcontents: [],
            roomkindcontents: [],
            sharedhousingRulescontents: [],
            rolelistcontents: [],
            amenitieslistcontents: [],
            isAddressSelected: false,
            NewAddress: '',
            ValidateErrorMessage: {
                AddressInfo: '',
            },
            PostmyAdsDataById: {},
            PostmyAdsDataPrevious:{}
        };

    }

    componentDidMount() {
        // const postID = 375;
        // GetPostMyAdById(postID).then((response) => {
            
        // })
        // console.log("previous data",this.state.PostmyAdsDataById.postMyAdAddress)
        const { postMyAdId } = this.props.router.params;
    console.log("postMYAdId",postMyAdId);
    
    GetPostMyAdById(postMyAdId).then((response) => {
        console.log("Fetched response:", response);
        
        // Save the response into the state
        this.setState((prevState) => ({
            customerInfo: {
                ...prevState.customerInfo, // Preserve other fields of customerInfo
                AddressInfo: response.data.postMyAdAddress.addressName // Update AddressInfo
            },
            PostmyAdsDataPrevious: response.data, // Save the entire response
            PostAdsId:response.data.postMyAdAddress.postMyAdId
        }), () => {
            // Logging after the state is updated
            console.log("Updated state:", this.state.PostmyAdsDataPrevious);
            console.log("Address :", this.state.customerInfo.AddressInfo);
        });
    }).catch((error) => {
        console.error("Error fetching data:", error);
    });


        GetMasterData()
            .then((response) => {
                const result = response.data;
                const { housingTypeList, roomDevelopmentList, roomKindList,
                    sharedHousingRulesList, postMyAdRoleList, amenitiesList } = result;

                const AmenitiesList = amenitiesList.map((rule) => {
                    return { key: rule.key, value: rule.value, checked: false }
                })

                const Houserules = sharedHousingRulesList.map((rule) => {
                    return { key: rule.key, value: rule.value, checked: false }
                })

                this.setState({
                    housingTypecontents: housingTypeList,
                    roomDevelopmentcontents: roomDevelopmentList,
                    roomkindcontents: roomKindList,
                    rolelistcontents: postMyAdRoleList,
                    amenitieslistcontents: AmenitiesList,
                    sharedhousingRulescontents: Houserules
                });
            })
            .catch(err => console.log(err))
            this.DisplayPostMyAdById(postMyAdId);
    }

    scrolltoBottom = () => {
        setTimeout(() => {
            var objDiv = document.getElementById("root");
            objDiv.scroll({ top: objDiv.scrollHeight, behavior: 'smooth' });
        }, 1000);
    }

    getLocation = (LocationInfo) => {
        const { address, latLng } = LocationInfo;
        let { customerInfo, ValidateErrorMessage } = this.state;
        customerInfo.AddressInfo = address;
        ValidateErrorMessage.AddressInfo = (address == "" ? "Please select address" : "");
        this.setState({ CurrentLocation: latLng, isAddressSelected: true, customerInfo })
        this.scrolltoBottom();
    }
    onClickgetAddressfromMap = (address) => {
        this.setState({ NewAddress: address })
    }

    onClickSubmit = () => {
        this.props.router.navigate('/viewadslist'); // Use navigate as a prop
    }

    onClickContinue = (panel) => {
        let { displayStatus, customerInfo, ValidateErrorMessage, PostAdsId } = this.state;
        let isGoogleMapAddressValid = true;
        console.log(PostAdsId);

        if (panel === "AdsList") {
            if (customerInfo.AddressInfo === "") {
                ValidateErrorMessage.AddressInfo = "Please select address";
                isGoogleMapAddressValid = false;
            }
            else {
                ValidateErrorMessage.AddressInfo = "";
            }
            this.setState({ ValidateErrorMessage })
            // this.NavigatePanel(customerInfo, displayStatus, panel)
            if (isGoogleMapAddressValid) {
                const { CurrentLocation, customerInfo } = this.state;
                this.setState({ isLoading: true })
                const { lat, lng } = CurrentLocation;
                const { userId } = this.props.LoggedInUserId;
                const payload = {
                    "postMyAdId": PostAdsId,
                    "userId": userId,
                    "addressName": customerInfo.AddressInfo.trim(),
                    "latitudes": lat,
                    "longitudes": lng,
                    "city" : "Chennai",
                    "state" : "Tamil Nadu",
                    "country": "India",
                    "postalcode":"600034",
                    "isActive": true
                }
                if(lat){
                    console.log("Updating")
                    PostHouseAddressUpdate(payload).then(response => {
                        const { isSuccess } = response.data;
                        if (isSuccess) {
                            this.setState({ isLoading: false }, () => {
                                this.DisplayPostMyAdById(PostAdsId);
                                this.NavigatePanel(customerInfo, displayStatus, panel)
                            })
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                }
                else{
                    this.NavigatePanel(customerInfo, displayStatus, panel)
                }
            
            }
            else {
                return isGoogleMapAddressValid;
            }
        }
        return isGoogleMapAddressValid;
    }

    callbackNavigatePanel = (panel) => {
        const { customerInfo, displayStatus, PostAdsId } = this.state;
        this.DisplayPostMyAdById(PostAdsId);
        this.NavigatePanel(customerInfo, displayStatus, panel)
    }
    DisplayPostMyAdById = (PostAdsId) => {
        GetPostMyAdById(PostAdsId).then((response) => {
            this.setState({ PostmyAdsDataById: { ...response.data } });
        })
    }

    NavigatePanel = (customerInfo, displayStatus, panel) => {
        Object.keys(displayStatus).forEach((item) => {
            if (item === panel)
                displayStatus[item] = true
            else
                displayStatus[item] = false
        })
        this.setState({ displayStatus: { ...displayStatus }, DataforDisplay: { ...customerInfo } })

        this.scrolltoBottom();
    }

    handleAddressChange = (event) => {
        const { customerInfo } = this.state;
        customerInfo.AddressInfo = event.target.value;
        this.setState({ customerInfo: customerInfo });
    }




    render() { 
        const { PostAdsId, displayStatus, customerInfo, PostmyAdsDataById, CurrentLocation,
            isAddressSelected, NewAddress, ValidateErrorMessage, isLoading, rolelistcontents, roomkindcontents,
            sharedhousingRulescontents, housingTypecontents,roomDevelopmentcontents,PostmyAdsDataPrevious  } = this.state;
        const {AddressInfo} =  customerInfo;

        return(
            <div className="container my-5 px-3 pt-3 pb-5 justify-content-center post-ads-container">
                <div className="row justify-content-center text-center">
                    <div className="col">
                        <h1 className="text-uppercase headerHeading">Post My Ad</h1>
                        <hr className="title-underline" />
                        <p className="lead">
                            <span className="text-custom">
                                Post your ad for FREE on BLLLD, the number 1 in shared accommodation!
                            </span>
                        </p>
                        <p className="text-muted ">Fields marked with an asterisk (*) are required</p>
                    </div>
                </div>
                {
                    PostAdsId > 0 && <div className="row justify-content-center mt-4">
                    <div className="col-10">
                        <DisplayDataEdit DataforDisplay={PostmyAdsDataById}
                         callbackNavigatePanel={this.callbackNavigatePanel}
                         HousingTypeList = {housingTypecontents}
                         AmenitiesList={this.state.amenitieslistcontents}
                         RoomkindList = {roomkindcontents} 
                         RoomDevelopmentList = {roomDevelopmentcontents}
                         SharedHousingRuleList={sharedhousingRulescontents}
                         AdsPosterRoleList = {rolelistcontents}/>
                    </div>
                </div>
                }
                
                <div className="row justify-content-center mt-4">
                    <div className="col-10 ">
                        <div className="post-input-container topdisplay btn-out-border border border-1">                        
                        {
                            displayStatus.Address && <div className="addressinfo">
                                <div className="row d-flex justify-content-center">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col-md-2"><label className="float-end" htmlFor="address">Address<span className="text-danger"> *</span></label></div>
                                            <div className="col-md-10">
                                                <div className="d-flex">
                                                    <LocationSearchInput AddressInfo={customerInfo.AddressInfo} getLocation={this.getLocation} scrolltoBottom = {this.scrolltoBottom} />
                                                    {
                                                        ValidateErrorMessage.AddressInfo !== "" && <span className="text-danger fw-bold">{ValidateErrorMessage.AddressInfo}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    NewAddress != '' && <div className="row">
                                        <div className="col text-center pt-2">
                                            <span>Selected Location from Map :</span> <span>{NewAddress}</span>
                                        </div>
                                    </div>

                                }
                                {
                                    isAddressSelected && (<div className="row d-flex justify-content-center">
                                        {
                                            <div className="col">
                                                <LocationWithGooglemap CurrentLocation={CurrentLocation} onClickgetAddressfromMap={this.onClickgetAddressfromMap} />
                                            </div>
                                        }
                                    </div>)
                                }
                                <div className="d-flex justify-content-center m-3 mt-4">
                                    <input type="button" value={"Update Address"} className="btn btn-continue"
                                        onClick={() => this.onClickContinue("AdsList")}></input>
                                </div>
                            </div>
                        }
                        { 
                            displayStatus.Housing && <div className="row">
                                <div className="col">
                                    <HousingEdit PostAdsId={PostAdsId}
                                        callbackNavigatePanel={this.callbackNavigatePanel}
                                        housingTypecontents={this.state.housingTypecontents}
                                        amenitieslistcontents={this.state.amenitieslistcontents}
                                        postMyAdHousing={this.state.PostmyAdsDataPrevious.postMyAdHousing} />
                                </div>
                            </div>
                        }
                         {
                            displayStatus.AbouttheRoom && <div className="row">
                                <AboutTheRoomEdit PostAdsId={PostAdsId}
                                    roomkindcontents={this.state.roomkindcontents}
                                    roomDevelopmentcontents={this.state.roomDevelopmentcontents}
                                    callbackNavigatePanel={this.callbackNavigatePanel}
                                    PostmyAdsDataPrevious = {this.state.PostmyAdsDataPrevious} />

                            </div>
                        }
                        {
                            displayStatus.CurrentRoommates && <div className="row">
                                <CurrentRoommatesEdit PostAdsId={PostAdsId}
                                    callbackNavigatePanel={this.callbackNavigatePanel}
                                    sharedhousingRulescontents={sharedhousingRulescontents}
                                    postMyAdIdealRoomMate = {this.state.PostmyAdsDataPrevious.postMyAdIdealRoomMate}
                                />
                            </div>
                        }
                        {
                            displayStatus.Yourprofile && <div className="row">
                                <div className="col">
                                    <YourProfileEdit rolelistcontents={rolelistcontents} PostAdsId={PostAdsId}
                                    AddressInfo={AddressInfo}
                                    callbackNavigatePanel={this.callbackNavigatePanel} 
                                    postMyAdProfile={this.state.PostmyAdsDataPrevious.postMyAdProfile}
/>
                                </div>

                            </div>
                        }
                        {
                            displayStatus.YourImage && <div className="row">
                                <div className="col">
                                    <YourImageEdit rolelistcontents={rolelistcontents} PostAdsId={PostAdsId}
                                    AddressInfo={AddressInfo}
                                    callbackNavigatePanel={this.callbackNavigatePanel} 
                                    postMyAdFiles={this.state.PostmyAdsDataPrevious.postMyAdFiles}
/>
                                </div>

                            </div>
                        }
                        {
                            displayStatus.YourVideo && <div className="row">
                                <div className="col">
                                    <YourVideoEdit rolelistcontents={rolelistcontents} PostAdsId={PostAdsId}
                                    callbackNavigatePanel={this.callbackNavigatePanel} 
                                    postMyAdFiles={this.state.PostmyAdsDataPrevious.postMyAdFiles}
/>
                                </div>

                            </div>
                        }
                        {
                            displayStatus.AdsList && <div className="row justify-content-center">
                            <div className="row justify-content-center">
                            </div>
                            <div className="row justify-content-center">
                            <div className="col-auto">
                                <input type="button" value={"Go Back"} className="btn btn-continue"
                                onClick={() => this.onClickSubmit()}></input>
                            </div>
                            </div>
                        </div>
                        }
                        
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    LoggedInUserId: loggedinUserIdSelector(state)
});
// export default connect(mapStateToProps)(PostyourAds)
export default connect(mapStateToProps)(withRouter(postadsupdate));
// export default postadsupdate;