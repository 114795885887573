import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import { classnames } from "../../Utilities"


class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: this.props.AddressInfo || '' };
  }

  handleChange = address => {
    this.setState({ address });
    //this.props.scrolltoBottom();
  };

  handleCloseClick = () => {
    this.setState({
      address: '',
      //latitude: null,
      // longitude: null,
    });
  };

  handleSelect = address => {
    console.log('handleSelect')
    this.setState({ address });
    geocodeByAddress(address)
      .then(results => { return getLatLng(results[0]) })
      .then(latLng => {
        this.props.getLocation({ address, latLng })
        //this.props.scrolltoBottom();
      })
      .catch(error => console.error('Error', error));
  };

  render() {
    return (
      <PlacesAutocomplete
        onChange={this.handleChange}
        value={this.state.address}
        onSelect={this.handleSelect}
      //  shouldFetchSuggestions={this.state.address.length > 2}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <div className="search-bar-container">
              <div className="search-input-container">
                <input
                  {...getInputProps({
                    placeholder: 'Enter your address: ex. 113 Rue Nicolas Welter Mersch, Luxembourg...',
                    className: 'search-input',
                  })}
                />
                {this.state.address.length > 0 && (
                  <button
                    className="clear-button"
                    onClick={this.handleCloseClick}
                  >
                    x
                  </button>
                )}
              </div>
              <div className= {suggestions.length>0 ?"autocomplete-dropdown-container":""} >
                {loading && <div>Loading...</div>}
                {suggestions.length > 0 && (
                  <div className="autocomplete-container">
                    {suggestions.map(suggestion => {
                      const className = classnames('suggestion-item', {
                        'suggestion-item--active': suggestion.active,
                      });

                      return (
                        /* eslint-disable react/jsx-key */
                        <div
                          {...getSuggestionItemProps(suggestion, { className })}
                        >
                          <strong>
                            {suggestion.formattedSuggestion.mainText}
                          </strong>{' '}
                          <small>
                            {suggestion.formattedSuggestion.secondaryText}
                          </small>
                        </div>
                      );
                      /* eslint-enable react/jsx-key */
                    })}
                    {/* <div className="dropdown-footer">
                  <div>
                    <img
                      src={require('../images/powered_by_google_default.png')}
                      className="dropdown-footer-image"
                    />
                  </div>
                </div> */}
                  </div>
                )}
              </div>
            </div>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}
export default LocationSearchInput;