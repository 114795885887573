import axios from "axios";
 
// API URL Controller Add the desired Link in this section
 
const baseURL = 'https://bllldwebapi-dev-a5hubnb0e9e7e2e4.germanywestcentral-01.azurewebsites.net/api'
 
// Google maps API
const directionsURL = 'https://www.google.com/maps/dir/?api=1'
const mapLoaderURL = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBaQS-DVHJ_xoqzXvTWZ8BqqpSzGtSJXQQ&loading=async&libraries=places'
 
 
//Sections (Main Landing Page)
 

//Country with City List

//Masterapi for Country List (File Present)

export function GetCityList(countryname) {
    return axios.get(`${baseURL}/master/GetCityList?country=${countryname}`);
}

export function GetStateList(countryname) {
    return axios.get(`${baseURL}/master/GetStateList?country=${countryname}`);
}


// Why Use BLLLD Section
export function WhyUseBllld() {
    return axios.get(`${baseURL}/WhyUseBllld`);
 
}
 
//Master Data API
export function MasterAPI() {
    return axios.get(`${baseURL}/master/GetMasterData`, {
    })
}

// forgot password
export function ForgotPasswordapi(Id) {
    return axios.post(`${baseURL}/SendSmsOtp?toMobile=%2B91${Id}`)
}
 
export function OTPcodeAPI(Id,code) {
    return axios.post(`${baseURL}/SendSmsOtp?toMobile=%2B91${Id}&${code}`)
}
 
export function ResetPasswordAPI(accessCode,newPassword){
    return axios.post(`${baseURL}/UpdatePassword?accessCode=${accessCode}&newPassword=${newPassword}`)
}
 
 
//Post Your Ads Section
 
 
export function GetPostMyAdById(Id) {
    return axios.get(`${baseURL}/PostMyAd/GetPostMyAdById?postMyAdId=${Id}`)
}
 
export function PostHouseAddress(payload) {
    return axios.post(`${baseURL}/PostMyAd/Address`, payload)
}
 
export function PostHouseDetails(payload) {
    return axios.post(`${baseURL}/PostMyAd/Housing`, payload)
}
 
export function PostAboutTheRoomDetails(payload) {
    return axios.post(`${baseURL}/PostMyAd/PostMyAdAboutRoom`, payload)
}
 
export function PostCurrentRoommatesDetails(payload) {
    return axios.post(`${baseURL}/PostMyAd/PostMyAdIdealRoomMate`, payload)
}
export function PostYourProfileDetails(payload) {
    return axios.post(`${baseURL}/PostMyAd/AddPostMyAdProfile`, payload)
}
 
export function uploadFile(PostAdsId, formData) {
    const url = `${baseURL}/upload?postMyAdId=${PostAdsId}`;

    return axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
}
export function uploadVideo(PostAdsId, formData) {
    const url = `${baseURL}/UploadVideoFile?postMyAdId=${PostAdsId}`;

    return axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
}



export function PostHouseAddressUpdate(payload) {
    return axios.post(`${baseURL}/PostMyAd/UpdateAddress`, payload)
}
export function PostHouseDetailsUpdate(payload) {
    return axios.post(`${baseURL}/PostMyAd/UpdateHousing`, payload)
}
export function PostAboutTheRoomDetailsUpdate(payload) {
    return axios.post(`${baseURL}/PostMyAd/UpdateAboutRoom`, payload)
}
 
export function PostCurrentRoommatesDetailsUpdate(payload) {
    return axios.post(`${baseURL}/PostMyAd/UpdateIdealRoomMate`, payload)
}
export function PostYourProfileDetailsUpdate(payload) {
    return axios.post(`${baseURL}/PostMyAd/UpdatePostMyAdProfile`, payload)
}
 
export function GetAdsInfo(postMyAdId) {
    return axios.get(
        `${baseURL}/PostmyAd/GetPostmyAdById?postmyAdId=${postMyAdId}`
    );
}
 
export function GetAdsList(userId) {
    return axios.get(
        `${baseURL}/PostMyAd/ViewMyPostList?userId=${userId}`
    );
}
 
export function addressUpdate(payload) {
    return axios.post(`${baseURL}/PostMyAd/UpdateAddress`, payload)  
}
 
export function DeletePostMyAd(Id) {
    return axios.delete(`${baseURL}/PostMyAd/DeletePostMyAd?postMyAdId=${Id}`)
}

export function DeletePostMyAdFile(Id) {
    return axios.delete(`${baseURL}/PostMyAd/DeletePostMyAdFile?fileId=${Id}`)
}
 
export function MYadsAPI() {
    return axios.get(`${baseURL}/PostmyAd/GetPostmyAdById?postmyAdId=1`)
}
 
export function getLoggerInUserData(accessToken) {            
       
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
    return axios.get(`${baseURL}/getuser`)
}
 

// Property Listings and Info View Section
 
//Get Property Listings
export function GetPropertyListings() {
    return axios.get(`${baseURL}/PostMyAd/GetPropertyList`);
}
 
 
//Master data API for Property Listings
export function GetMasterData() {
    return axios.get(`${baseURL}/master/GetMasterData`)
}
 
// For Property Details View
 
// export function GetAdsInfo(postMyAdId) {
//     return axios.get(
//         `${baseURL}/PostmyAd/GetPostmyAdById?postmyAdId=${postMyAdId}`
//     );
// }

//Get Property Listings by Filters API (unsupported Api call error)

// export function GetPropertyByFilterList(payload) {
//     return axios.get(
//         `${baseURL}/PostMyAd/GetPropertyByFilterList`,
//             payload
        
//     );
// }

export function GetPropertyByFilterList(payload) {
    return axios.post(
        `${baseURL}/PostMyAd/GetPropertyByFilterList`,
        payload, // Here, we send the payload as the request body
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    );
}

// Property Listing Filter (Country and Statename)

export function GetPropertyListByStatename(country,state) {
    return axios.get(`${baseURL}/PostMyAd/GetPropertyByCountryList?country=${country}&state=${state}`);
}

// Property Listings Filter (Country and Cityname)

export function GetPropertyListByCityname(country,city) {
    return axios.get(`${baseURL}/PostMyAd/GetPropertyByCountryList?country=${country}&city=${city}`)
}

 
//Google Maps API
 
export {directionsURL};  //For Directions Handler (Google Maps)
 
export {mapLoaderURL}; //Map Loader (Google maps API)
 
 
//Sign in and Login Section
 
export function SignUpUser(payload) {
        return axios.post(`${baseURL}/signup`,payload)
    }
 
export function LoginUser(payload) {
        return axios.post(`${baseURL}/login`,payload)
    }
 
 
 
 
 
 

// --------------------------------------------------------------------------------------------