import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom"; 
import DisplayData from './DisplayData'
import { PanelList } from '../../constant/PostAdsPanelList'
import LocationSearchInput from './LocationSearchInput'
import LocationWithGooglemap from "./LocationWithGooglemap";
import { loggedinUserIdSelector } from "../../state/selectors/login.selector"

import YourProfile from './YourProfile'
import YourAccount from './YourAccount'
import Housing from './Housing'
import AboutTheRoom from './AboutTheRoom'
import CurrentRoommates from './CurrentRoommates'
import { GetMasterData, PostHouseAddress, GetPostMyAdById } from '../../api/apiLoader'
import "./PostAds.scss"
import { withRouter } from "../../hooks/useRouteHooks";
import YourVideo from "./YourVideo";

class PostyourAds extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            PostAdsId: 0,
            isLoading: false,
            CurrentLocation: {},
            customerInfo: {
                AddressInfo: "",
                AddressData: {}
            },
            displayStatus: {
                Address: true,
                Housing: false,
                AbouttheRoom: false,
                CurrentRoommates: false,
                Yourprofile: false,
                Youraccount: false,
                YourVideo:false,
                AdsList: false
            },
            housingTypecontents: [],
            roomDevelopmentcontents: [],
            roomkindcontents: [],
            sharedhousingRulescontents: [],
            rolelistcontents: [],
            amenitieslistcontents: [],
            isAddressSelected: false,
            NewAddress: '',
            ValidateErrorMessage: {
                AddressInfo: '',
            },
            PostmyAdsDataById: {}
        };
    }
    
    scrolltoBottom = () => {
        setTimeout(() => {
            var objDiv = document.getElementById("root");
            objDiv.scroll({ top: objDiv.scrollHeight, behavior: 'smooth' });
        }, 1000);
    }
        
    componentDidMount() {
        GetMasterData()
            .then((response) => {
                const result = response.data;
                const { housingTypeList, roomDevelopmentList, roomKindList,
                    sharedHousingRulesList, postMyAdRoleList, amenitiesList } = result;

                const AmenitiesList = amenitiesList.map((rule) => {
                    return { key: rule.key, value: rule.value, checked: false }
                })

                const Houserules = sharedHousingRulesList.map((rule) => {
                    return { key: rule.key, value: rule.value, checked: false }
                })

                this.setState({
                    housingTypecontents: housingTypeList,
                    roomDevelopmentcontents: roomDevelopmentList,
                    roomkindcontents: roomKindList,
                    rolelistcontents: postMyAdRoleList,
                    amenitieslistcontents: AmenitiesList,
                    sharedhousingRulescontents: Houserules
                });
            })
            .catch(err => console.log(err))
            //this.DisplayPostMyAdById(253);
    }

    getLocation = (LocationInfo) => {
        const { address, latLng } = LocationInfo;
        let { customerInfo, ValidateErrorMessage } = this.state;
        customerInfo.AddressInfo = address;
        ValidateErrorMessage.AddressInfo = (address == "" ? "Please select address" : "");
        this.setState({ CurrentLocation: latLng, isAddressSelected: true, customerInfo })
        this.scrolltoBottom();
    }
    onClickgetAddressfromMap = (address) => {
        this.setState({ NewAddress: address })
        this.setState(prevState => ({
            customerInfo: {
                ...prevState.customerInfo,
                AddressInfo: address
            }
        }));
    }

    //submit and redirect
        onClickSubmit = () => {
            this.props.router.navigate('/viewadslist'); // Use navigate as a prop
        }

    //Continue Function to route to the next Page
    onClickContinue = (panel) => {
        let { displayStatus, customerInfo, ValidateErrorMessage } = this.state;
        let isGoogleMapAddressValid = true;
 
        if (panel === PanelList.HOUSING) {
            if (customerInfo.AddressInfo === "") {
                ValidateErrorMessage.AddressInfo = "Please select address";
                isGoogleMapAddressValid = false;
            }
            else {
                ValidateErrorMessage.AddressInfo = "";
            }
            this.setState({ ValidateErrorMessage })
            if (isGoogleMapAddressValid) {
                const { CurrentLocation, customerInfo } = this.state;
                this.setState({ isLoading: true })
                const { lat, lng } = CurrentLocation;
                const { userId } = this.props.LoggedInUserId;
                const payload = {
                    "userId": userId,
                    "addressName": customerInfo.AddressInfo.trim(),
                    "latitudes": lat,
                    "longitudes": lng,
                    "city" : "Chennai",
                    "state" : "Tamil Nadu",
                    "country": "India",
                    "postalcode":"600034",
                    "isActive": true
                }
                console.log(payload);
                    this.setState(prevState => ({
                        customerInfo: {
                            ...prevState.customerInfo,
                            AddressData: payload
                        },
                        isLoading: false
                    }));
                PostHouseAddress(payload).then(response => {
                    const { isSuccess, message, result: PostAdsId } = response.data;
                    if (isSuccess) {
                        this.setState({ PostAdsId, isLoading: false }, () => {
                            this.DisplayPostMyAdById(PostAdsId);
                            this.NavigatePanel(customerInfo, displayStatus, panel)
                        })
                    }
                }).catch(err => {
                    console.log(err)
                })
            }
            else {
                return isGoogleMapAddressValid;
            }
        }
        return isGoogleMapAddressValid;
    }

    callbackNavigatePanel = (panel) => {
        const { customerInfo, displayStatus, PostAdsId } = this.state;
        this.DisplayPostMyAdById(PostAdsId);
        this.NavigatePanel(customerInfo, displayStatus, panel)
    }
    DisplayPostMyAdById = (PostAdsId) => {
        GetPostMyAdById(PostAdsId).then((response) => {
            this.setState({ PostmyAdsDataById: { ...response.data } });
        })
    }

    NavigatePanel = (customerInfo, displayStatus, panel) => {
        Object.keys(displayStatus).forEach((item) => {
            if (item === panel)
                displayStatus[item] = true
            else
                displayStatus[item] = false
        })
        this.setState({ displayStatus: { ...displayStatus }, DataforDisplay: { ...customerInfo } })

        this.scrolltoBottom();
    }

    handleAddressChange = (event) => {
        const { customerInfo } = this.state;
        customerInfo.AddressInfo = event.target.value;
        this.setState({ customerInfo: customerInfo });
    }

    render() { 
        const { PostAdsId, displayStatus, customerInfo, PostmyAdsDataById, CurrentLocation,
            isAddressSelected, NewAddress, ValidateErrorMessage, isLoading, rolelistcontents, roomkindcontents,
            sharedhousingRulescontents, housingTypecontents,roomDevelopmentcontents  } = this.state;
        const {AddressInfo,AddressData} =  customerInfo;
        return (
            <div className="container my-5 px-3 pt-3 pb-5 justify-content-center post-ads-container">
                <div className="row justify-content-center text-center">
                    <div className="col">
                        <h1 className="text-uppercase headerHeading">Post My Ad</h1>
                        <hr className="title-underline" />
                        <p className="lead">
                            <span className="text-custom">
                                Post your ad for FREE on BLLLD, the number 1 in shared accommodation!
                            </span>
                        </p>
                        <p className="text-muted ">Fields marked with an asterisk (*) are required</p>
                    </div>
                </div>
                {
                    PostAdsId > 0 && <div className="row justify-content-center mt-4">
                    <div className="col-10">
                        <DisplayData DataforDisplay={PostmyAdsDataById} HousingTypeList = {housingTypecontents}
                         AmenitiesList={this.state.amenitieslistcontents}
                         RoomkindList = {roomkindcontents} 
                         RoomDevelopmentList = {roomDevelopmentcontents}
                         SharedHousingRuleList={sharedhousingRulescontents}
                         AdsPosterRoleList = {rolelistcontents}/>
                    </div>
                </div>
                }
                
                <div className="row justify-content-center mt-4">
                    <div className="col-10 ">
                        <div className="post-input-container topdisplay btn-out-border border border-1">                        
                        {
                            displayStatus.Address && <div className="addressinfo">
                                <div className="row d-flex justify-content-center">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col-md-2"><label className="float-end" htmlFor="address">Address<span className="text-danger"> *</span></label></div>
                                            <div className="col-md-10">
                                                <div className="d-flex">
                                                    <LocationSearchInput getLocation={this.getLocation} scrolltoBottom = {this.scrolltoBottom} />
                                                    {
                                                        ValidateErrorMessage.AddressInfo !== "" && <span className="text-danger fw-bold">{ValidateErrorMessage.AddressInfo}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    NewAddress != '' && <div className="row">
                                        <div className="col text-center pt-2">
                                            <span>Selected Location from Map :</span> <span>{NewAddress}</span>
                                        </div>
                                    </div>

                                }
                                {
                                    isAddressSelected && (<div className="row d-flex justify-content-center">
                                        {
                                            <div className="col">
                                                <LocationWithGooglemap CurrentLocation={CurrentLocation} onClickgetAddressfromMap={this.onClickgetAddressfromMap} />
                                            </div>
                                        }
                                    </div>)
                                }
                                <div className="d-flex justify-content-center m-3 mt-4">
                                    <input type="button" value={"Confirm Address"} className="btn btn-continue"
                                        onClick={() => this.onClickContinue(PanelList.HOUSING)}></input>
                                </div>
                            </div>
                        }
                        { 
                            displayStatus.Housing && <div className="row">
                                <div className="col">
                                    <Housing PostAdsId={PostAdsId}
                                        callbackNavigatePanel={this.callbackNavigatePanel}
                                        housingTypecontents={this.state.housingTypecontents}
                                        amenitieslistcontents={this.state.amenitieslistcontents} />
                                </div>
                            </div>
                        }
                        {
                            displayStatus.AbouttheRoom && <div className="row">
                                <AboutTheRoom PostAdsId={PostAdsId}
                                    roomkindcontents={this.state.roomkindcontents}
                                    roomDevelopmentcontents={this.state.roomDevelopmentcontents}
                                    callbackNavigatePanel={this.callbackNavigatePanel} />

                            </div>
                        }
                        {
                            displayStatus.CurrentRoommates && <div className="row">
                                <CurrentRoommates PostAdsId={PostAdsId}
                                    callbackNavigatePanel={this.callbackNavigatePanel}
                                    sharedhousingRulescontents={sharedhousingRulescontents}
                                />
                            </div>
                        }
                        {
                            displayStatus.Yourprofile && <div className="row">
                                <div className="col">
                                    <YourProfile rolelistcontents={rolelistcontents} PostAdsId={PostAdsId}
                                    AddressInfo={AddressInfo} AddressData={AddressData}
                                    callbackNavigatePanel={this.callbackNavigatePanel} />
                                </div>

                            </div>
                        }
                        {
                            displayStatus.Youraccount && <div className="row">
                                <div className="col">
                                    <YourAccount rolelistcontents={rolelistcontents} PostAdsId={PostAdsId}
                                        callbackNavigatePanel={this.callbackNavigatePanel} />
                                </div>

                            </div>
                        }
                        {
                            displayStatus.AdsList && <div className="row justify-content-center">
                            <div className="row justify-content-center">
                            <div className="alert alert-success mt-2  w-75 text-center">
                                "Your details have been submitted for verification , Please wait for administrative approval"
                            </div>
                            </div>
                            <div className="row justify-content-center">If you want to add images of your property</div>
                            <div className="row justify-content-center">
                            <YourAccount rolelistcontents={rolelistcontents} PostAdsId={PostAdsId}
                                        callbackNavigatePanel={this.callbackNavigatePanel} />
                                        <div className="mb-3 row justify-content-center">
                                <input type="button" value={"Upload Video"} className="btn btn-continue"
                                        onClick={() => this.callbackNavigatePanel("YourVideo")}></input>
                                        </div>
                                </div>
                            <div className="row justify-content-center">
                            <div className="col-auto">
                                <input type="button" value={"View my post"} className="btn btn-continue"
                                onClick={() => this.onClickSubmit()}></input>
                            </div>
                            </div>
                        </div>
                        }
                        {
                            displayStatus.YourVideo && <div className="row justify-content-center">
                            <div className="row justify-content-center">
                            <div className="alert alert-success mt-2  w-75 text-center">
                                "Your details have been submitted for verification , Please wait for administrative approval"
                            </div>
                            </div>
                            <div className="row justify-content-center">If you want to add video of your property</div>
                            <div className="row justify-content-center">
                            <YourVideo rolelistcontents={rolelistcontents} PostAdsId={PostAdsId}
                                        callbackNavigatePanel={this.callbackNavigatePanel} />
                                </div>
                            <div className="row justify-content-center">
                            <div className="col-auto">
                                <input type="button" value={"View my post"} className="btn btn-continue"
                                onClick={() => this.onClickSubmit()}></input>
                            </div>
                            </div>
                        </div>
                        }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    LoggedInUserId: loggedinUserIdSelector(state)
});
// export default connect(mapStateToProps)(PostyourAds)
export default connect(mapStateToProps)(withRouter(PostyourAds));