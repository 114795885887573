
export const PanelList = {
    ADDRESS:'Address',
    HOUSING:'Housing',
    ABOUT_THE_ROOM:'AbouttheRoom',
    CURRENT_ROOMMATES:'CurrentRoommates',
    YOUR_PROFILE:'Yourprofile',
    YOUR_ACCOUNT:'Youraccount'
}

export const GenderList = ['Man','Women','Other'];

export const ProfessionalList = ['Student','Employeed','UnEmployeed','Other'];
