import {createSelector} from "reselect"

const loginResult = (state)=>state.loginState.isUserLoggedin;

const loggedUserResult = (state)=>state.loginState.LoggedInuserData;

const loginSelector = createSelector([loginResult],(result)=>result);

const loggedinUserDataSelector = createSelector([loggedUserResult],(result)=>result);

const loggedinUserIdSelector = createSelector([loggedUserResult],(result)=> {
    const { userId } = result;
    return {userId};
});



export { 
    loginSelector,
    loggedinUserDataSelector,
    loggedinUserIdSelector
}