import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

class Footer extends React.Component {
  componentDidMount() {
    // this.setBackgroundImage();
  }
  setBackgroundImage = () => {
    const mainContainer = document.getElementById('mainContainer')
    mainContainer.className = "background-postads-page";
  }
  render() {
    return (
      <>
        <div className="col-12 footer">
          <div className="row">          
            <div className="col footer-buttons">
              <div className="d-flex flex-row m-3 mb-3 justify-content-end">
                <div className="p-2 mx-3"><Link className="nav-link" to="#">About Us</Link></div>
                <div className="p-2 mx-3"><Link className="nav-link" to="#">Need help?</Link></div>
                <div className="p-2 mx-3"><Link className="nav-link" to="#">Bllld Terms of Use</Link></div>
                <div className="p-2 mx-3"><Link className="nav-link" to="#">Privacy Policy</Link></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col footer-copyright float-end">
              <p className="text-center text-white  mt-3">Copyright © 2024 Bllld. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Footer;
