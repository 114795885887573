import React from "react";
import { connect } from "react-redux";
import { uploadVideo } from "../../api/apiLoader";
 
class YourVideo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            fileName: '',
            selectedFile: null,
            fileDetailsList: [],
            inputLink: '', // To store the input link (YouTube link)
            isYoutubeLink: false, // To check if the input is a YouTube link
        };
    }
 
    handleInputChange = (event) => {
        const value = event.target.value;
        const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/; // Regex to check if it's a YouTube link
        this.setState({
            inputLink: value,
            isYoutubeLink: youtubeRegex.test(value) // Check if the link matches YouTube URL format
        });
    };
 
    handleUpload = () => {
        const { selectedFile, inputLink, isYoutubeLink } = this.state;
        const PostAdsId = this.props.PostAdsId;
 
        // If it's a YouTube link, open it in a new tab
        if (isYoutubeLink) {
            window.open(inputLink, '_blank');
            return;
        }
 
        // Otherwise, handle the video upload logic
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
 
            uploadVideo(PostAdsId, formData)
                .then(response => {
                    console.log('Upload successful:', response.data);
 
                    if (response.data.isSuccess) {
                        const videoFiles = (response.data.fileDetailsList || []).filter(file => file.isImageFile === false);
                        this.setState({
                            fileDetailsList: videoFiles,
                            fileName: '',
                            selectedFile: null
                        });
                    }
                })
                .catch(error => {
                    console.error('Error uploading file:', error);
                });
        }
    };
 
    renderFileGrid = () => {
        const { fileDetailsList } = this.state;
 
        if (fileDetailsList.length === 0) {
            return null;
        }
 
        return (
            <div className="container-table col-lg-10">
                <ul className="responsive-table">
                    <li className="table-header">
                        <div className="col">S.No</div>
                        <div className="col-9 text-center">File Name</div>
                        <div className="col">Actions</div>
                    </li>
                    {fileDetailsList.map((fileDetail, index) => (
                        <li className="table-row" key={index}>
                            <div className="col" data-label="S.No">{index + 1}</div>
                            <div className="col-9" data-label="File Name">{fileDetail.fileName}</div>
                            <div className="col" data-label="Actions">
                                <button className="btn" title="Delete">
                                    <i className="fa-solid fa-trash-can text-danger"></i>
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };
 
    render() {
        const { fileDetailsList, inputLink, isYoutubeLink } = this.state;
 
        return (
            <>
                <div className="row justify-content-center my-5">
                    <div className="col col-lg-7 col-md-10 col-sm-12">
                        {/* Only show the upload input and button if there are fewer than 6 files */}
                        {fileDetailsList.length < 6 && (
                            <div className="form-group of yourvideo">
                                <div className="input-group ">
                                    <span className="input-group-text px-3 ">
                                        <i className="fas fa-link fa-lg"></i>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg"
                                        placeholder="Upload Video Link "
                                        value={inputLink}
                                        onChange={this.handleInputChange}
                                    />
                                    <button
                                        className="browse btn btn-browse px-4"
                                        type="button"
                                        onClick={this.handleUpload}
                                    >
                                        <i className="fas fa-upload" /> {isYoutubeLink ? 'Upload' : 'Upload'}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
 
                <div className="container row justify-content-center">
                    {this.renderFileGrid()}
                </div>
            </>
        );
    }
}
 
const mapStateToProps = (state) => ({
    // Add necessary state props here if needed
});
 
export default connect(mapStateToProps)(YourVideo);
 
 
 