import React from "react";
import './headerfilter.css';
import { extend } from "lodash";

class HeaderFilter extends React.Component {
    render() {
        return (
            <div className="col p-0">
                <div className="background-wrapper">
                    <div className="inner-background">
                        <div className="features-section">
                            <h2>BEST PLACE TO FIND AND EXPLORE<br />THAT ALL YOU NEED</h2>
                            <p className="mt-3">Find Best Place, Restaurant, and many more things in just one Click</p>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default HeaderFilter;
