import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { withRouter } from "../hooks/useRouteHooks.js";
import "./Login.css";
import { LoginUser, getLoggerInUserData } from "../api/apiLoader.js";
import { connect } from "react-redux";
import { setLoginStatus, setLoggedUserData } from "../state/actions/login.status.js";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      isLoading: false,
      networkError: "",
      isSuccess: false,
      responseMessage: "",
      redirectToHome: false,
    };
  }

  componentDidMount() {
    //this.setBackgroundImage();
  }

  setBackgroundImage = () => {
    const mainContainer = document.getElementById("mainContainer");
    if (mainContainer) {
      mainContainer.className = "background-login-page";
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { username, password } = this.state;

    if (!username || !password) {
      this.setState({
        responseMessage: "Please enter both username and password",
        isSuccess: false,
      });
      return;
    }

    this.setState({ isLoading: true });

    const data = {
      loginName: username,
      password: password,
      isEmail: false,
    };

    LoginUser(data)
      .then((response) => {
        const { message = "", isLoginSuccess, accessToken } = response.data;
        if (isLoginSuccess) {
          getLoggerInUserData(accessToken)
            .then((userResponse) => {
              const { userId, firstName, lastName, emailId, mobile, isSuccess = false, message = "" } = userResponse.data;
              if (isSuccess) {
                const userData = {
                  userId, firstName, lastName, emailId, mobile
                };
                this.props.dispatch(setLoginStatus());
                this.props.dispatch(setLoggedUserData(userData));
                const { navigate } = this.props.router;
                navigate(`/home`);
              }
            })
        } else {
          this.setState({
            isSuccess: false,
            responseMessage: message,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          networkError: "Network error",
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { username, password, isLoading, networkError, responseMessage, isSuccess, redirectToHome } =
      this.state;
      
    return (
      <div id="mainContainer" className="text-center">
        <div className="container">
          <div className="row justify-content-center mb-5 mt-5">
            <main className="mainform col-md-4 pt-3 mt-5 mb-5 rounded">
              <form className="mb-2" onSubmit={this.handleSubmit}>
                <h1 className="login-title mb-3 text-light ">Login</h1>
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    name="username"
                    value={username}
                    onChange={this.handleChange}
                  />
                  <label htmlFor="floatingInput"><i class="fa-solid fa-user"></i>   E-mail or Phone No</label>
                </div>
                <br />
                <div className="form-floating">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                  />
                   <label htmlFor="floatingPassword"><i class="fa-solid fa-lock"></i> Password</label>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-2 mb-3">
                  <label className="mb-0 text-light">
                    <input type="checkbox" value="remember-me" /> Remember Me
                  </label>
                  <Link to="/forgot-password" className="forgotlink">Forgot Password?</Link>
                </div>
                <div className="buttonsignin rounded">
                  <button className="w-100 btn btn-lg" type="submit">
                    {isLoading ? "Signing in..." : "Sign in"}
                  </button>
                </div>

                {networkError && (
                  <div className="alert alert-danger mt-2">{networkError}</div>
                )}
                {responseMessage && (
                  <div
                    className={`alert ${isSuccess ? "alert-success" : "alert-danger"
                      } mt-2`}
                  >
                    {responseMessage}
                  </div>
                )}

                
                <div className="register mb-4 mt-3 text-center text-light">
                  <span>Not registered? </span>
                  <Link to="/signup" className="signuplink">Sign up</Link>
                </div>
              </form>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(Login));
