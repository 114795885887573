import React from "react";
import "./featuressection.css";
import axios from "axios";
import {  Link } from "react-router-dom";
 
import Chaticon from "../../assets/icons/chat-left-dots.svg";
import House from "../../assets/icons/house.svg";
import PeopleFill from "../../assets/icons/people-fill.svg";
import { WhyUseBllld } from "../../api/apiLoader";
 
 
// Map icons to corresponding feature titles based on API response
const iconMapping = {
    "Find a roo": House,
    "Find roommates": PeopleFill,
    "We are here to help": Chaticon,
};
 
class featuressection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            featurescontent: []
        };
    }
 
    componentDidMount() {
        WhyUseBllld()
            .then((response) => {
                const result = response.data;
                this.setState({ featurescontent: result });
            })
            .catch((error) => {
                console.log("There was an error fetching the data!", error);
            });
    }  
    render() {
        return (
            <div className="col p-0">
                <div className="featuresmainContainer">
                    <div className="container p-3 pb-md-4 mx-auto text-center px-4 py-5 hanger" id="hanging-icons">
                        <h2 className="pb-2">WHY USE BLLLD?</h2>
                        <hr className="title-underline" />
                        <p className="featuremainpara">
                            A happy roommate is the roommate who stays longer! At Bllld,
                            <br />
                            our priority is quality connections
                        </p>
                        <div className="row">
                            {this.state.featurescontent.map((feature) => (
                                <FeatureItem
                                    key={feature.id}
                                    icon={iconMapping[feature.titleText] || Chaticon} // Default to Chaticon if not found
                                    title={feature.titleText}
                                    content={feature.summary}
                                    buttoncontent={feature.buttonText}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>            
        );
    }
}
 
const FeatureItem = ({ title, content, buttoncontent, icon }) => {
    let title1 = '', title2 = '';
    let CssName='';
    title = title.replace("Find a roo", "Find a room")
    if (title.indexOf("Find a roo") > -1 || title.indexOf("Find a room") > -1) {
        title1 = 'Find';
        title2 = title.split('Find').filter(ele => ele !== '').join().trim().replace("a room", "A Room")
        CssName="button-blue"
    }
    else if (title.indexOf("Find roommates") > -1) {
        title1 = 'Find';
        title2 = title.split('Find').filter(ele => ele !== '').join().trim().replace("roommates", "Roommates");
         CssName="button-red"
    }
    else if (title.indexOf("We are here to help") > -1) {
        title1 = 'We are';
        title2 = title.split('We are').filter(ele => ele !== '').join().trim();
         CssName="button-blue"
    }

    //To Check the Buttoncontent text temp
    let buttonlink;

    if(buttoncontent == "FIND A ROOM"){
        buttonlink = "/hotel-list";
    }
    else if (buttoncontent == "PUBLISH YOUR ROOM") {
        buttonlink = "/post-your-ads";
    }
    else{
        buttonlink= "/help";
    }


    let chunks = content.match(/.{1,180}(?= |$)/g)[0];
    chunks = content.length<=180? chunks : chunks +' . . .';
    return (
       
        <div className="col">
            <div className="card card-size rounded-3 border-0">
                <div className="card-header">
                    <div className="row">
                        <div className="col-3">
                            <img src={icon} alt={title} className="icon-size" />
                        </div>
                        <div className="col-9 vertical-line">
                            <h4 className="text-start title1">{title1}</h4>
                            <h4 className="text-start title2">{title2}</h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="featurespara bg-transparent">{chunks}</div>  
                </div>
                <div className="card-footer text-muted">                 
                    <Link className={`btn ${CssName}`} to={buttonlink}>{buttoncontent}</Link>
                </div>
            </div>
        </div>
    );
};
 
export default featuressection;
 
 