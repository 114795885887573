import React from "react";
import { connect } from "react-redux";
import { DeletePostMyAdFile, uploadFile } from "../../api/apiLoader";

class YourImageEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            fileName: '', 
            selectedFile: null,
            fileDetailsList: [] // To store file details from API response
        };
    }
    // componentDidMount = () => {
    //     const { postMyAdFiles } = this.props;

    //     if (postMyAdFiles && postMyAdFiles.fileDetailsList) {
    //         this.setState({
    //             fileDetailsList: postMyAdFiles.fileDetailsList
    //         });
    //     }
    // }

    componentDidMount = () => {
    const { postMyAdFiles } = this.props;

    if (postMyAdFiles && postMyAdFiles.fileDetailsList) {
        const imageFiles = postMyAdFiles.fileDetailsList.filter(file => file.isImageFile === true);
        this.setState({
            fileDetailsList: imageFiles // Only store files where isImageFile is true
        });
    }
}
    onClickContinue(panel)
  {
    this.props.callbackNavigatePanel(panel)


  }

    handleFileChange = (event) => {
        const file = event.target.files[0]; 
        if (file) {
            this.setState({ fileName: file.name, selectedFile: file });
        }
    };

    handleUpload = () => {
        const { selectedFile } = this.state;
        const PostAdsId = this.props.PostAdsId; 
        // const PostAdsId = 362;
        console.log(PostAdsId);
    
        if (selectedFile) {
            // Create a FormData object
            const formData = new FormData();
            formData.append('file', selectedFile);
    
            // Use axios to make the POST request
            uploadFile(PostAdsId, formData)
                .then(response => {
                    console.log('Upload successful:', response.data);

                    // Update state with the uploaded file details where isImageFile is true
                    if (response.data.isSuccess) {
                        const imageFiles = (response.data.fileDetailsList || []).filter(file => file.isImageFile === true);
                        this.setState({
                            fileDetailsList: imageFiles, // Only store files where isImageFile is true
                            fileName: '', // Clear the file name after successful upload
                            selectedFile: null // Clear the selected file after successful upload
                        });
                    }
                })
                .catch(error => {
                    console.error('Upload failed:', error);
                    // Handle error if needed
                });
        }
    };
    handleDelete = (fileId) => {
        DeletePostMyAdFile(fileId)
            .then(response => {
                console.log('File deleted successfully');
                this.setState(prevState => ({
                    fileDetailsList: prevState.fileDetailsList.filter(file => file.fileId !== fileId)
                }));
            })
            .catch(error => {
                console.error('There was an error deleting the file!', error);
            });
    };
    

    renderFileGrid = () => {
        const { fileDetailsList } = this.state;
    
        if (fileDetailsList.length === 0) {
            return null;
        }
    
        return (
            <div className="container-table col-lg-10">
                <ul className="responsive-table">
                    {/* Table Header */}
                    <li className="table-header">
                        <div className="col">S.No</div>
                        <div className="col-9 text-center">File Name</div>
                        <div className="col">Actions</div>
                    </li>
    
                    {/* Dynamically render file details */}
                    {fileDetailsList.map((fileDetail, index) => (
                        <li className="table-row" key={index}>
                            <div className="col" data-label="S.No">{index + 1}</div>
                            <div className="col-9" data-label="File Name">{fileDetail.fileName}</div>
                            <div className="col" data-label="Actions">
                                <button className="btn" title="Delete" onClick={() => this.handleDelete(fileDetail.fileId)}>
                                    <i className="fa-solid fa-trash-can text-danger"></i>
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };
    

    render() {
        const { fileDetailsList, selectedFile } = this.state;
    
        return (
            <>
                <div className="row justify-content-center my-5">
                    <div className="col col-lg-7 col-md-10 col-sm-12">
                        {/* Only show the upload section if there are less than 6 files */}
                        {fileDetailsList.length < 6 && (
                            <div className="form-group">
                                <div className="input-group shadow">
                                    <span className="input-group-text px-3 text-muted">
                                        <i className="fas fa-image fa-lg"></i>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg"
                                        placeholder="Upload Image"
                                        value={this.state.fileName}
                                        readOnly
                                    />
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        ref={(fileInput) => (this.fileInput = fileInput)}
                                        onChange={this.handleFileChange}
                                    />
                                    <button className="browse btn btn-browse px-4" type="button" onClick={() => this.fileInput.click()}>
                                        <i className="fas fa-image" /> Browse
                                    </button>
                                </div>
                            </div>
                        )}
    
                        {/* Show the upload button only if a file is selected */}
                        {selectedFile && fileDetailsList.length < 6 && (
                            <div className="text-center mt-3">
                                <button className="btn btn-primary" onClick={this.handleUpload}>
                                    <i className="fas fa-upload"></i> Upload
                                </button>
                            </div>
                        )}
                        
                    </div>
                </div>
    
                {/* Render file details below the upload button */}
                <div className="container row justify-content-center">
                    {this.renderFileGrid()}
                </div>
                <div className="row justify-content-center p-3">
                    <div className="col-auto">
                        <input type="button" value={"Update"} className="btn btn-continue"
                            onClick={() => this.onClickContinue('AdsList')}></input>
                    </div>
                </div>
            </>
        );
    }
    
}

const mapStateToProps = (state) => ({
    // Add necessary state props here if needed
});

export default connect(mapStateToProps)(YourImageEdit);
