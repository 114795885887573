import React from "react";
import axios from "axios";
import "./Help.css";

class Help extends React.Component {
  state = {
    faqCategories: [],
    faqQuestions: [],
    selectedCategoryId: null,
  };

  componentDidMount() {
    axios
      .get(
        "https://bllldwebapi-dev-a5hubnb0e9e7e2e4.germanywestcentral-01.azurewebsites.net/api/faq/GetFaqData"
      )
      .then((response) => {
        const result = response.data;
        this.setState({
          faqCategories: result.faqCategoryList,
          faqQuestions: result.faqQuestionAnswerList,
          selectedCategoryId: result.faqCategoryList[0]?.categoryId || null,
        });
        console.log(result);
      })
      .catch((error) => {
        console.log("There was an error fetching the data!", error);
      });

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleCategorySelect = (categoryId) => {
    this.setState({ selectedCategoryId: categoryId });
  };

  handleScroll = () => {
    const menu = document.querySelector('.menu');
    const qussection = document.querySelector('.qussection');
    const scrollY = window.scrollY;
    const qussectionBottom = qussection.offsetTop + qussection.offsetHeight;

    if (scrollY + window.innerHeight > qussectionBottom) {
      menu.style.position = 'absolute';
      menu.style.top = `${qussectionBottom - menu.offsetHeight}px`;
    } else {
      menu.style.position = 'sticky';
      menu.style.top = '0px';
    }
  };

  render() {
    const { faqCategories, faqQuestions, selectedCategoryId } = this.state;

    const filteredQuestions = faqQuestions.filter(
      (question) => question.categoryId === selectedCategoryId
    );

    const otherQuestions = faqQuestions.filter(
      (question) => question.categoryId !== selectedCategoryId
    );

    return (
      <>
        <h1 className="Frequently text-center m-5">
          Frequently Asked <b className="qus">Questions</b>
        </h1>
        <div className="helpsec row">
          <div className="col-4 p-4">
            <div className="menu">
              {faqCategories.map((category) => (
                <button
                  key={category.categoryId}
                  className={`mb-3 ${category.categoryId === selectedCategoryId ? "active" : ""}`}
                  onClick={() => this.handleCategorySelect(category.categoryId)}
                >
                  {category.categoryName}
                </button>
              ))}
            </div>
          </div>
          <div className="col-8 p-4">
            <div className="qussection p-4">
              <div className="accordion accordion-flush" id="accordionFlushExample">
                {filteredQuestions.map((question) => (
                  <div className="bg-light" key={question.questionId}>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse${question.questionId}`}
                          aria-expanded="false"
                          aria-controls={`flush-collapse${question.questionId}`}
                        >
                          <strong>{question.questionText}</strong>
                        </button>
                      </h2>
                      <div
                        id={`flush-collapse${question.questionId}`}
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">{question.answer}</div>
                      </div>
                    </div>
                  </div>
                ))}
                {otherQuestions.map((question) => (
                  <div className="bg-light" key={question.questionId}>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse${question.questionId}`}
                          aria-expanded="false"
                          aria-controls={`flush-collapse${question.questionId}`}
                        >
                          <strong>{question.questionText}</strong>
                        </button>
                      </h2>
                      <div
                        id={`flush-collapse${question.questionId}`}
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">{question.answer}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Help;
