import React from "react";
import { directionsURL } from "../../api/apiLoader";
import { withRouter } from "../../hooks/useRouteHooks";  // Important for Routing
import './Hotel.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel, CarouselItem } from "react-bootstrap";
import { imgAssests } from "../../assets/imgAssests/assests";

class PropertyCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onHotelClick = (HotelId) => {
    const { navigate } = this.props.router;
    navigate(`/hotel-info/${HotelId}`);
  };

  opendirections = (latitudes, longitudes) => {
    window.open(`${directionsURL}&origin=Current+Location&destination=${latitudes},${longitudes}`, '_blank');
  };

  getChunckedData = (content) => {
    let chunks = content.match(/.{1,36}(?= |$)/g)[0];
    chunks = content.length <= 36 ? chunks : chunks + ' . . .';
    return chunks;
  };

  render() {
    const {
      postMyAdId,
      professionalSituation,
      adTitle,
      address,
      monthlyRent,
      availabilityDate,
      minimumDuration,
      roomKindName,
      roomDevelopmentName,
      sex,
      hasPrivateBathroom,
      latitudes,
      longitudes,
      postMyAdFileDetailList,
    } = this.props.property;

    return (
      <div className="row mb-4 rounded-4 cardsection">
        <div className="col-md-6 position-relative">
          <span className="badge bg-warning text-dark pill-position m-2">
            <i className="fa-solid fa-graduation-cap"></i> Preferred by {professionalSituation}
          </span>
          <Carousel fade>
            {postMyAdFileDetailList.map((screenshot) => {
              const correctedpath = screenshot.filePath.includes("https://") ? screenshot.filePath : screenshot.filePath.replace("https:/","https://");
              return(
                <CarouselItem key={screenshot.fileId}>
                  <div className="property-listings-carousal w-100" style={{ backgroundImage: `url(${correctedpath})` }}></div>
                </CarouselItem>
              );
            })}
          </Carousel>
        </div>
        <div className="col-md-6">
          <div className="card-body d-grid customproperty-listgap">
            <h5 className="card-title mt-1" onClick={() => this.onHotelClick(postMyAdId)}>{adTitle}</h5>
            <p className="card-text text-muted" onClick={() => this.onHotelClick(postMyAdId)}>
              {this.getChunckedData(address)}
            </p>
            <p className="start-name" onClick={() => this.onHotelClick(postMyAdId)}>
              Starts from <br />
              <span className="h3">€<strong>{monthlyRent}</strong></span> /mo<span className="property-star">*</span>
            </p>
            <div className="washroom-uppersection d-flex flex-wrap gap-3">
              <button className="btn btn-outline-secondary btn-sm">
                Availability Date <i className="fa-regular fa-calendar-check"></i><br />
                {availabilityDate.slice(0, 10)}
              </button>
              <button className="btn btn-outline-secondary btn-sm">
                Minimum Duration <i className="fa-solid fa-clock-rotate-left"></i><br />
                {minimumDuration} months
              </button>
            </div>
            <div className="propertyfeatures-buttons d-flex flex-wrap gap-2">
              <button className="btn btn-outline-secondary btn-sm">
                <i className="fa-solid fa-bed"></i> {roomKindName}
              </button>
              <button className="btn btn-outline-secondary btn-sm">
                <i className="fa-solid fa-chair"></i> {roomDevelopmentName}
              </button>
              <button className="btn btn-outline-secondary btn-sm">
                <i className="fa-solid fa-user-group"></i> {sex}
              </button>
              {hasPrivateBathroom && (
                <button className="btn btn-outline-secondary btn-sm">
                  <i className="fa-solid fa-bath"></i> Attached WC
                </button>
              )}
            </div>
            <div className="Request-section mt-1 d-flex flex-wrap gap-2">
              <a href="#" className="mt-1" onClick={() => this.opendirections(latitudes, longitudes)}>View Directions</a>
              <button className="btn btn-sm schedule-visit-btn">Contact</button>
              <button className="btn btn-sm schedule-visit-btn">Schedule visit</button>
            </div>
          </div>
        </div> 
      </div>
    );
  }
}

export default withRouter(PropertyCards);  // Wrap the component with withRouter


{/* <div className="col-12 col-md-8 col-lg-11 mb-4">
<div className="card shadow-sm border-0 mb-4 rounded-4">
  <div className="cardsection row g-0 rounded-4">
    <div className="col-12 col-md-6 image-container position-relative">
      <span className="badge bg-warning text-dark pill-position m-2">
        <i className="fa-solid fa-graduation-cap"></i> Preferred by {professionalSituation}
      </span>
      {/* <img
        src="https://bllldblobstoragenonprod.blob.core.windows.net/bllldcontainer/501/house2.jpg"
        className="h-100 w-100 rounded-start-4"
        alt="housesample"
      /> */}
      // <Carousel fade>
      //   {postMyAdFileDetailList.map((screenshot) => {
      //     const correctedpath = screenshot.filePath.includes("https://") ? screenshot.filePath : screenshot.filePath.replace("https:/","https://");
      //     return(
      //       <CarouselItem key={screenshot.fileId}>
      //         <div className="property-listings-carousal" style={{ backgroundImage: `url(${correctedpath})` }}></div>
      //       </CarouselItem>
      //     )
      //   })}
      // </Carousel>

//     </div>
//     <div className="col-12 col-md-6">
//       <div className="card-body">
//         <h5 className="card-title" onClick={() => this.onHotelClick(postMyAdId)}>
//           {adTitle}
//         </h5>
//         <p className="card-text text-muted ms-0" onClick={() => this.onHotelClick(postMyAdId)}>
//           {this.getChunckedData(address)}
//         </p>
//         {/* this.onHotelClick(this.props.postMyAdId) -> Use this also */}
//         <p className="start-name ms-0" onClick={() => this.onHotelClick(postMyAdId)}>
//           Starts from <br />
//           <span className="h3">€<strong>{monthlyRent}</strong></span> /mo<span className="property-star">*</span>
//         </p>
//         <div className="washroom-uppersection d-flex flex-wrap gap-3 mt-3">
//             <button className="btn btn-outline-secondary btn-sm">
//                 Availability Date <i className="fa-regular fa-calendar-check"></i><br />
//                 {availabilityDate.slice(0, 10)}
//             </button>
//             <button className="btn btn-outline-secondary btn-sm">
//                 Minimum Duration <i className="fa-solid fa-clock-rotate-left"></i><br />
//                 {minimumDuration} months
//             </button>
//         </div>
//         <div className="propertyfeatures-buttons d-flex flex-wrap gap-2 mt-3">
//           <button className="btn btn-outline-secondary btn-sm">
//             <i className="fa-solid fa-bed"></i> {roomKindName}
//           </button>
//           <button className="btn btn-outline-secondary btn-sm">
//             <i className="fa-solid fa-chair"></i> {roomDevelopmentName}
//           </button>
//           <button className="btn btn-outline-secondary btn-sm">
//             <i className="fa-solid fa-user-group"></i> {sex}
//           </button>
//           {hasPrivateBathroom && (
//             <button className="btn btn-outline-secondary btn-sm">
//               <i className="fa-solid fa-bath"></i> Attached Washroom
//             </button>
//           )}
//         </div>
//         <div className="Request-section mt-2 d-flex flex-wrap gap-2">
//           <a href="#" className="mt-2" onClick={() => this.opendirections(latitudes, longitudes)}>View Directions</a>
//           <button className="btn btn-sm schedule-visit-btn">Contact</button>
//           <button className="btn btn-sm schedule-visit-btn">Schedule visit</button>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// </div> 
