import React from "react";
import "./viewmyads";
import "./viewmyads.css"
import { withRouter } from "../../hooks/useRouteHooks";
import { GetAdsInfo, MasterAPI } from "../../api/apiLoader";
 
class ViewMyAds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewadsform: [], // Initial state for holding the ads list
 
      // State for Master API data
      housingTypecontents: [],
      roomDevelopmentcontents: [],
      roomkindcontents: [],
      sharedhousingRulescontents: [],
      rolelistcontents: [],
      amenitieslistcontents: [],
 
      // State for GetAdsInfo data
      postMyAdAddress: {},
      postMyAdHousing: {
        amenityIdList: [], // Initialize as an empty array to prevent undefined errors
      },
      postMyAdAboutRoom: {},
      postMyAdIdealRoomMate: {},
      postMyAdProfile: {},
      postMyAdFiles: {}
    };
  }
 
  componentDidMount() {
 
    // const postMyAdId = 63;
    // const { postMyAdId } = this.props.match.params;
 
    const { postMyAdId } = this.props.router.params;
    console.log("postMYAdId",postMyAdId);
 
   
 
    // Fetching GetAdsInfo data
    GetAdsInfo(postMyAdId)
      .then((response) => {
        const result = response.data;
 
        // Manually replace keys in amenityIdList from 1, 2 to 6, 7
        const modifiedAmenityIdList = result.postMyAdHousing.amenityIdList.map(
          (id) => {
            if (id === 1) return 6;
            if (id === 2) return 7;
            return id; // Return the original id if it's not 1 or 2
          }
        );
 
        this.setState({
          viewadsform: result,
          postMyAdAddress: result.postMyAdAddress,
          postMyAdHousing: {
            ...result.postMyAdHousing,
            amenityIdList: modifiedAmenityIdList,
          },
          postMyAdAboutRoom: result.postMyAdAboutRoom,
          postMyAdIdealRoomMate: result.postMyAdIdealRoomMate,
          postMyAdProfile: result.postMyAdProfile,
          postMyAdFiles: result.postMyAdFiles
        });
        console.log(result); // Log the whole result for debugging
      })
      .catch((error) => {
        console.log("There was an error fetching the GetAdsInfo data!", error);
      });
 
 
    // Fetching Master API data
    MasterAPI()
    .then((response) => {
      const result = response.data;
      this.setState({
        housingTypecontents: result.housingTypeList,
        roomDevelopmentcontents: result.roomDevelopmentList,
        roomkindcontents: result.roomKindList,
        sharedhousingRulescontents: result.sharedHousingRulesList,
        rolelistcontents: result.postMyAdRoleList,
        amenitieslistcontents: result.amenitiesList,
      });
    })
    .catch((error) => {
      console.error("There was an error fetching the Master API data", error);
    });
  }
  onClickSubmit = () => {
    this.props.router.navigate('/viewadslist'); // Use navigate as a prop
}
 
  render() {
    const {
      postMyAdAddress,
      postMyAdHousing,
      postMyAdAboutRoom,
      postMyAdIdealRoomMate,
      postMyAdProfile,
      postMyAdFiles,
      housingTypecontents,
      amenitieslistcontents,
      roomkindcontents,
      roomDevelopmentcontents,
      sharedhousingRulescontents,
      rolelistcontents
    } = this.state;
    const imageFiles = (postMyAdFiles.fileDetailsList || []).filter(file => file.isImageFile === true);
    const videoFiles = (postMyAdFiles.fileDetailsList || []).filter(file => file.isImageFile === false);
 
    // Find the housing type value based on the housingTypeId
    const housingTypeValue = housingTypecontents.find(
      (item) => item.key === postMyAdHousing.housingTypeId
    )?.value;
 
    // Find the amenities values based on the amenityIdList
    const amenitiesValues = postMyAdHousing.amenityIdList.map(
      (amenityId) =>
        amenitieslistcontents.find((item) => item.key === amenityId)?.value
    );
 
     // Find the room kind value based on the roomKindId
     const roomKindValue = roomkindcontents.find(
      (item) => item.key === postMyAdAboutRoom.roomKindId
  )?.value;
 
  // Find the room development value based on the roomDevelopmentId
  const roomDevelopmentValue = roomDevelopmentcontents.find(
      (item) => item.key === postMyAdAboutRoom.roomDevelopmentId
  )?.value;
 
  const rolelistValue = rolelistcontents.find(
    (item) => item.key === postMyAdProfile.postMyAdRoleId
  )?.value;
 
    return (
      <>
        <div
          className="container full-sectionfonts p-4 mt-5 text-start btn-out-border border border-5"
          style={{ backgroundColor: "#f5f8fc", borderRadius: "20px" }}
        >
          <div className="row mb-3">
            <div className="col-2">
              <strong className="viewads-headers">Address:</strong>
            </div>
            <div className="col-10 pb-3"> {postMyAdAddress.addressName}</div>
          </div>
 
          <div className="row mb-3 pb-2">
            <div className="col-2">
              <strong className="viewads-headers">Housing:</strong>
            </div>
            <div className="col-10">
              <div className="row">
                <div className="col d-flex">
                  <div className="mr-3">
                    <strong>Housing type</strong> <span className="text-danger"> *</span>
                  </div>
                </div>
                <div className="col d-flex">
                  <div className="ml-auto">
                    <strong>Total Room(s)</strong>
                  </div>
                </div>
                <div className="col d-flex">
                  <div className="ml-auto">
                    <strong>No Bathroom(s)</strong>
                  </div>
                </div>
                <div className="col d-flex">
                  <div className="ml-auto">
                    <strong>No Rooms for Rent</strong>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col d-flex">
                  <div className="mr-3">{housingTypeValue}</div>{" "}
                  {/* Displaying the value */}
                </div>
                <div className="col d-flex">
                  <div className="ml-auto">
                    {postMyAdHousing.totalRoomCount}
                  </div>
                </div>
                <div className="col d-flex">
                  <div className="ml-auto">
                    {postMyAdHousing.totalBathroomCount}
                  </div>
                </div>
                <div className="col d-flex">
                  <div className="ml-auto">
                    {postMyAdHousing.totalRoomRentCount}
                  </div>
                </div>
              </div>
              <div className="row pt-3 ">
                <div className="col d-flex">
                  <div className="text-start col-2">
                    <strong>Amenities</strong>
                  </div>
                  <div className="col-8 d-flex flex-wrap">
                    {amenitiesValues.map((amenity, index) => (
                      <button
                        key={index}
                        className="btn btn-outline-secondary btn-sm me-3 mb-2"
                      >
                        {amenity}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
 
          <div className="row pb-3">
            <div className="col-2">
              <strong className="viewads-headers">About The Room:</strong>
            </div>
            <div className="col-10">
              <div className="row">
                <div className="col d-flex">
                  <div className="mr-3">
                    <strong>Kind</strong> <span className="text-danger"> *</span>
                  </div>
                </div>
                <div className="col d-flex">
                  <div className="mr-3">
                    <strong>Development</strong> <span className="text-danger"> *</span>
                  </div>
                </div>
                <div className="col d-flex">
                  <div className="mr-3">
                    <strong>Monthly Rent</strong> <span className="text-danger"> *</span>
                  </div>
                </div>
 
                <div className="col d-flex">
                  <div className="mr-3">
                    <strong>Room area(m2)</strong>
                  </div>
                </div>
              </div>
 
            <div className="row mt-2">
                <div className="col d-flex">
                  <div className="mr-3">{roomKindValue}</div>
                </div>
                <div className="col d-flex">
                  <div className="mr-3">{roomDevelopmentValue}</div>
                </div>
                <div className="col d-flex">
                  <div className="mr-3">{postMyAdAboutRoom.monthlyRent}</div>
                </div>
                <div className="col d-flex">
                  <div className="mr-3">{postMyAdAboutRoom.roomArea}</div>
                </div >
            </div>
            <div className="row pt-3">
                 <div className="col d-flex ">
                    {postMyAdAboutRoom.isChargesNotIncluded && (
                    <div>
                    <span style={{ color: "#ff545a", fontWeight: 'bold' }}>&#10003;</span><span>   Charges not included</span>
                    </div>
                 )}
                </div>
                <div className="col d-flex ">
                    {postMyAdAboutRoom.hasPrivateBathroom && (
                    <div>
                    <span style={{ color: "#ff545a", fontWeight: 'bold' }}>&#10003;</span><span>   PrivateBathroom</span>
                    </div>
                 )}
                </div>
            </div>
 
              <div className="row pt-3">
                <div className="col d-flex">
                  <div className="mr-3">
                    <strong>Availability Date</strong>
                  </div>
                </div>
                <div className="col d-flex">
                  <div className="mr-3">
                    <strong>Minimum Duration</strong>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col d-flex">
                  <div className="mr-3">
                    {postMyAdAboutRoom.availabilityDate}
                  </div>
                </div>
                <div className="col d-flex">
                  <div className="mr-3">{postMyAdAboutRoom.minimumDuration}</div>
                </div>
              </div>
            </div>
          </div>
 
 
          <div className="row mb-3">
                <div className="col-2">
                  <strong className="viewads-headers"> Current Roommates:</strong>
                </div>
                <div className="col-10">
                  <div className="row">
                    <div className="col d-flex">
                      <div className="mr-3">
                        <strong>
                          Number of roommates living in accommodation
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col d-flex">
                      <div className="mr-3">
                        {postMyAdIdealRoomMate.currentRoommatesCount}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
 
              <div className="row mb-3">
                <div className="col-2">
                  <strong className="viewads-headers">Your ideal roommate:</strong>
                </div>
                <div className="col-10">
                  <div className="row">
                    <div className="col d-flex">
                      <div className="mr-3">
                        <strong>Sex</strong> <span className="text-danger"> *</span>
                      </div>
                    </div>
                    <div className="col d-flex">
                      <div className="mr-3">
                        <strong>Professional Situation</strong>
                      </div>
                    </div>
                    <div className="col d-flex">
                      <div className="mr-3">
                        <strong>Age from</strong>
                      </div>
                    </div>
                    <div className="col d-flex">
                      <div className="mr-3">
                        <strong>Age Upto </strong>
                      </div>
                    </div>
                  </div>
 
                  <div className="row mt-2">
                    <div className="col d-flex">
                      <div className="mr-3">{postMyAdIdealRoomMate.sex}
                      </div>
                    </div>
                    <div className="col d-flex">
                      <div className="mr-3">
                        {postMyAdIdealRoomMate.professionalSituation}
                      </div>
                    </div>
                    <div className="col d-flex">
                      <div className="mr-3">{postMyAdIdealRoomMate.ageFrom}</div>
                    </div>
                    <div className="col d-flex">
                      <div className="mr-3">{postMyAdIdealRoomMate.ageTo}</div>
                    </div>
                  </div>
                </div>
 
 
                <div className="row pt-3">
  <div className="col-2">
    <strong className="viewads-headers">Rules Of Your Shared Accommodation:</strong>
  </div>
  <div className="col-10">
    <div className="row mt-2">
      {/* Check if saRuleList exists and is an array */}
      {Array.isArray(postMyAdIdealRoomMate.saRuleList) &&
        postMyAdIdealRoomMate.saRuleList.length > 0 &&
        postMyAdIdealRoomMate.saRuleList.map((ruleId, index) => {
          // Check if sharedhousingRulescontents is an array and find the rule
          const rule = Array.isArray(sharedhousingRulescontents) &&
            sharedhousingRulescontents.find(
              (item) => item.key === ruleId
            )?.value;
 
          return rule ? (
            <div className="col d-flex" key={index}>
              <div>
                <span
                  style={{ color: "#ff545a", fontWeight: "bold" }}
                >
                  &#10003;
                </span>
                <span> {rule}</span>
              </div>
            </div>
          ) : null;
        })}
    </div>
  </div>
</div>
 
 
<div className="row pt-3">
              <div className="col-2">
                <strong className="viewads-headers">Your Profile:</strong>
              </div>
              <div className="col-10">
                <div className="row">
                  <div className="col d-flex">
                    <div className="mr-3">
                      <strong>About You</strong> <span className="text-danger"> *</span>
                    </div>
                  </div>
                  <div className="col d-flex">
                    <div className="mr-3">
                      <strong>Phone</strong> <span className="text-danger"> *</span>
                    </div>
                  </div>
                  <div className="col d-flex">
                    <div className="mr-3">
                      <strong>First Name</strong> <span className="text-danger"> *</span>
                    </div>
                  </div>
                  <div className="col d-flex">
                    <div className="mr-3">
                      <strong>Last Name</strong> <span className="text-danger"> *</span>
                    </div>
                  </div>
                </div>
   
                <div className="row mt-2">
                  <div className="col d-flex">
                    <div className="mr-3">{rolelistValue}</div>
                  </div>
                  <div className="col d-flex">
                    <div className="mr-3">{postMyAdProfile.phoneNumber}</div>
                  </div>
                  <div className="col d-flex">
                    <div className="mr-3">{postMyAdProfile.firstName}</div>
                  </div>
                  <div className="col d-flex">
                    <div className="mr-3">{postMyAdProfile.lastName}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* Description*/}
            <div className="row pt-2">
              <div className="col-2">
                <strong className="viewads-headers">Description :</strong>
              </div>
              <div className="col-10">
                <div className="row pt-2">
                  <div className="col d-flex">
                    <div className="mr-3">
                      <strong> Ad Title</strong> <span className="text-danger"> *</span>
                    </div>
                  </div>
                </div>
   
                <div className="row pt-2">
                  <div className="col d-flex">
                    <div className="mr-3">{postMyAdProfile.adTitle}</div>
                  </div>
                </div>
   
                <div className="row pt-2">
                  <div className="col d-flex">
                    <div className="mr-3">
                      <strong>
                        Description Of Your Shared Accommodation
                      </strong> <span className="text-danger"> *</span>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col d-flex">
                    <div className="mr-3">
                    {postMyAdProfile.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>    
            <div className="row">
           <div className="col-2 text-danger">
           <strong>Your images :</strong>
    {/* <span className="label-1">Your images</span> */}
</div>
<div className="col-10">
    <div className="row">
        {postMyAdFiles && postMyAdFiles.fileDetailsList && imageFiles.length > 0 ? (
            imageFiles.map((file, index) => (
                <div key={file.fileId} className="col-4 mb-3 d-flex flex-wrap gap-1">
                    <img
                        className="w-75 rounded"
                        src={file.filePath}
                        alt={file.fileName}
                    />
                </div>
            ))
        ) : (
            <p>No images available.</p>
        )}
    </div>
</div>
</div>
<div className="row">
           <div className="col-2 text-danger">
           <strong>Your Video :</strong>
    {/* <span className="label-1">Your images</span> */}
</div>
<div className="col-10">
    <div className="row">
        {postMyAdFiles && postMyAdFiles.fileDetailsList && videoFiles.length > 0 ? (
            videoFiles.map((file, index) => (
                <div key={file.fileId} className="col-4 mb-3 d-flex flex-wrap gap-1">
                    <video width="400" height="240" controls>
                        <source src={file.filePath} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            ))
        ) : (
            <p>No video available.</p>
        )}
    </div>
</div>
</div>
 
         
           <div className="row justify-content-center">
                            <div className="col-auto">
                                <input type="button" value={"Go Back"} className="btn btn-continue"
                                onClick={() => this.onClickSubmit()}></input>
                            </div>
                            </div>
           </div>
        </div>
      </>
    );
  }
}
 
export default withRouter(ViewMyAds);
 
 
 